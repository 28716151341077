import Adminpage from '../../components/adminmenu';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Modal } from 'bootstrap';
import 'bootstrap/js/dist/modal';

import { SERVER } from "../../keyvariable";
import 'bootstrap/js/dist/modal';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';




const AdminNews = () =>{
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const viewModalRef = useRef(null);
    const [article, setArticle] = useState({});
    const [section, setSection] = useState([]);
    const [coverphoto, setCoverphoto] = useState("");
    const [activeTab, setActiveTab] = useState('published');

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${SERVER}main/newsList`);
            const newsList = response.data.newsList;
    
            const updatedNewsList = newsList.map(news => ({
                ...news,
                base64Image: `${SERVER}uploads/news/${news.image.originalName}` 
            }));
    
            setData(updatedNewsList);
            


        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleViewArticle = (data) => {
        
        setArticle(data);
        setCoverphoto(`${SERVER}uploads/news/${data.image.originalName}`);
        
        const modalElement = new Modal(viewModalRef.current);
        modalElement.show();
        
    }

    const handleEditArticle = (data) => {
        const id = data._id;
        navigate(`/news-admin/edit/${id}`);
        
    }

    const resetForm =()=>{
        setArticle({});
    }

    const handleTab = (tab) =>{
        setActiveTab(tab);

        // console.log(tab);
        
    }


    const handleDeleteArticle = async(data) =>{
        try {
           const response = await axios.delete(`${SERVER}main/newsDelete/${data._id}`) ;

           if(response.status === 200){
                getData();
           }
           
        } catch (error) {
            console.log(error);
            
        }
        
    }

    return (
        <div style={{ minHeight: "100vh" }}>
            <Adminpage />
            <div className="side-content container mx-auto mt-5 pt-5 mt-md-5 pt-md-0">
                <div className="admin-header">
                    <div className="me-auto ">
                        <h2 className='m-0 p-0'>All Articles</h2>
                       
                    </div>
                    {/* <div>
                        <a href="/news-admin/create" className='create-news-btn'>
                            <i className="fa-solid fa-plus"></i> Create News
                        </a>
                    </div> */}
                </div>
                
                <div className='news-mini-nav mb-3 mt-3 d-flex flex-column-reverse flex-md-row row-gap-3 justify-content-start'>
                    <div className="me-auto">
                        <nav className="nav nav-underline justify-content-start">
                            <a className={`nav-link ${activeTab === 'published' ? 'active' : ''}`} href="#" onClick={(e)=> {
                                e.preventDefault(); 
                                handleTab('published')
                            }}>
                                Published
                            </a>
                            <a className={`nav-link ${activeTab === 'draft' ? 'active' : ''}`} href="#" onClick={(e)=>{
                                e.preventDefault();
                                handleTab('draft')
                            }}>
                                Draft
                            </a>
                            {/* <a className="nav-link" href="#">Scheduled</a> */}
                        </nav>
                    </div>
                    <div className='d-flex me-auto ms-3'>
                        <input className='search-news' placeholder='Search...' />
                        {/* <button>Filter</button> */}
                    </div>
                </div>


                
                <div>
                {loading ? (
                    <p>Loading...</p>
                ) : (

                    <div className="articles-container">
                        <div className="articles-container-head">
                            <div className="me-auto">
                                {
                                    activeTab === 'published' ?
                                    (
                                        `${data.filter(data=> data.status==='publish').length} Results` 
                                    )
                                    :
                                    (
                                       `${data.filter(data=> data.status==='draft').length} Results`
                                    )
                                        

                                }
                            </div>
                            <div>
                                <NavLink to="/news-admin/create" className='create-article-btn' >
                                    <i className="fa-solid fa-plus" style={{marginRight:"6px"}}></i>
                                    Create Article
                                </NavLink>
                            </div>
                        </div>
                        {
                            activeTab === 'published' ?

                            (
                                Array.isArray(data) && data.filter(data=>data.status==='publish').map((rowData, index) => {

                                    const truncatedDescription = rowData.sections
                                        .map((section) => {
                                            const paragraphColumn = section.columns.find((column) => column.type === 'paragraph');
                                            if (paragraphColumn) {
                                                const content = paragraphColumn.content;
                                                const descriptionWords = (content || '').split(' ');
                                                return descriptionWords.slice(0, 16).join(' ') + (descriptionWords.length > 16 ? '...' : '');
                                            }
                                            return null;
                                        })
                                        .filter(Boolean)[0];

                                    return (
                                        
                                        <div key={index} className="news-list row">
                                            <div className="image-container col-md-2 col-12" >
                                                {rowData.base64Image ? ( 
                                                    <img src={rowData.base64Image} alt={rowData.title} />
                                                ) : (
                                                    <p>No image available</p> 
                                                )}
                                            </div>

                                            <div className='col-md-7 col-12'>
                                                <h5 className='m-0'>{rowData.title}</h5>
                                                <h6 className='mb-2'>{moment(rowData.date).format('MMM/DD/YYYY h:mm A')}</h6>
                                                <p className='m-0'>
                                                    {truncatedDescription}
                                                </p>
                                            </div>
                                            
                                            <div className='col-md-3 col-12 m-0 p-0 d-flex justify-content-start justify-content-md-end'>
                                                <button className="btn btn-primary m-1" onClick={() => handleViewArticle(rowData)}><i className="fa-solid fa-eye"></i></button>
                                                <button className="btn btn-success m-1" onClick={() => handleEditArticle(rowData)}><i className="fas fa-edit"></i></button>
                                                <button className="btn btn-danger m-1" onClick={()=> handleDeleteArticle(rowData)}><i className="fa-solid fa-trash"></i></button>
                                            </div>

                                        </div>
                                        
                                    );
                                })
                            )
                            :
                            (
                                Array.isArray(data) && data.filter(data=>data.status==='draft').map((rowData, index) => {

                                    const truncatedDescription = rowData.sections
                                        .map((section) => {
                                            const paragraphColumn = section.columns.find((column) => column.type === 'paragraph');
                                            if (paragraphColumn) {
                                                const content = paragraphColumn.content;
                                                const descriptionWords = (content || '').split(' ');
                                                return descriptionWords.slice(0, 16).join(' ') + (descriptionWords.length > 16 ? '...' : '');
                                            }
                                            return null;
                                        })
                                        .filter(Boolean)[0];

                                    return (
                                        
                                        <div key={index} className="news-list row">
                                            <div className="image-container col-md-2 col-12" >
                                                {rowData.base64Image ? ( 
                                                    <img src={rowData.base64Image} alt={rowData.title} />
                                                ) : (
                                                    <p>No image available</p> 
                                                )}
                                            </div>

                                            <div className='col-md-7 col-12'>
                                                <h5 className='m-0'>{rowData.title}</h5>
                                                <h6 className='mb-2'>{moment(rowData.date).format('MMM/DD/YYYY h:mm A')}</h6>
                                                <p className='m-0'>
                                                    {truncatedDescription}
                                                </p>
                                            </div>
                                            
                                            <div className='col-md-3 col-12 m-0 p-0 d-flex justify-content-end'>
                                                <button className="btn btn-primary m-1" onClick={() => handleViewArticle(rowData)}><i className="fa-solid fa-eye"></i></button>
                                                <button className="btn btn-success m-1" onClick={() => handleEditArticle(rowData)}><i className="fas fa-edit"></i></button>
                                                <button className="btn btn-danger m-1"><i className="fa-solid fa-trash"></i></button>
                                            </div>

                                        </div>
                                        
                                    );
                                })
                            )

                        }
                    </div>
                    


                )}
                </div>
            </div>

            
            <div ref={viewModalRef} className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="deleteModalLabel">View Data</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={resetForm} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {/* Cover Photo */}
                        <div className="page-header article-header" style={{ backgroundImage: `url(${coverphoto})` }}>
                            <div className="article-overlay"></div>
                            <h1 className="fw-bold text-center">{article.title}</h1>
                            <div className="sub-heading">
                                <p>Published: {moment(article.date).format('MMM/DD/YYYY h:mm A')}</p>
                                <p>By: {article.author}</p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="article-body">
                            {data.filter((data) => data._id === article._id).map((sectionItem, index) => (
                               
                                    sectionItem.sections.map((section, sectionIndex) => {
                                        return(
                                            <div key={sectionIndex}>
                                                <div className="right-image-article mb-3 d-flex align-items-center">
                                                    {
                                                        section.columns.map((column, columnIndex) => {
                                                            if (column.type === 'paragraph') {
                                                            return (
                                                                <div 
                                                                    className="article-text-wrapper d-flex flex-column me-md-3 mb-3 flex-grow-1" 
                                                                    key={columnIndex}
                                                                    style={{ display: 'flex', alignItems: 'stretch' }}
                                                                >
                                                                    <p 
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: column.content.replace(/\n/g, '<br />')
                                                                        }}
                                                                        style={{ margin: 0 }}
                                                                    ></p>
                                                                </div>
                                                                // <p key={columnIndex} dangerouslySetInnerHTML={{ __html: column.content.replace(/\n/g, '<br />') }}></p>
                                                            );
                                                            } else if (column.type === 'image' && column.image) {
                                                            return (
                                                                <div 
                                                                    className="article-image-wrapper mx-auto mb-3 flex-grow-1" 
                                                                    key={columnIndex}
                                                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch' }}
                                                                >
                                                                    <img
                                                                        src={`${SERVER}/uploads/news/${column.image.originalName}`}
                                                                        alt={column.image.alt || `Image ${columnIndex + 1}`}
                                                                        style={{ maxWidth: '100%', height: 'auto', objectFit: 'contain' }}
                                                                    />
                                                                </div>
                                                                // <img
                                                                // key={columnIndex}
                                                                // src={`${SERVER}/uploads/news/${column.image.originalName}`} // Ensure SERVER is defined
                                                                // alt={column.image.alt || `Image ${columnIndex + 1}`} // Default alt text
                                                                // style={{ maxWidth: '100%', height: 'auto' }}
                                                                // />
                                                            );
                                                            }

                                                            return null;
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                        

                                            
                                    })
                                    
                            ))}


                            </div>


                            {/*------------------------- carousell-------------------------------- */}

                            <div id="carouselExampleDark" className="carousel carousel-dark slide p-100">
                                {/* Carousel Indicators */}
                                {data.filter((data) => data._id === article._id).flatMap(item => item.subphoto).length > 1 && (
                                    <div className="carousel-indicators p-100">
                                        {Array.from({ length: Math.ceil(data.filter((data) => data._id === article._id).flatMap(item => item.subphoto).length / 2) }).map((_, index) => (
                                            <button
                                                type="button"
                                                data-bs-target="#carouselExampleDark"
                                                data-bs-slide-to={index}
                                                className={index === 0 ? "active" : ""}
                                                aria-current={index === 0 ? "true" : "false"}
                                                aria-label={`Slide ${index + 1}`}
                                                key={index}
                                            ></button>
                                        ))}
                                    </div>
                                )}

                                {/* Carousel Inner */}
                                <div className="carousel-inner">
                                    {data.filter((data) => data._id === article._id).flatMap(item => item.subphoto).reduce((acc, photo, index, arr) => {
                                        // Group photos into pairs for each slide
                                        if (index % 2 === 0) {
                                            const pair = arr[index + 1] ? [photo, arr[index + 1]] : [photo];
                                            acc.push(pair);
                                        }
                                        return acc;
                                    }, []).map((pair, index) => (
                                        <div
                                            className={`carousel-item ${index === 0 ? "active" : ""}`}
                                            key={index} // Unique key for each slide
                                            data-bs-interval="10000"
                                        >
                                            <div className="row justify-content-center">
                                                {pair.map((photo, photoIndex) => (
                                                    <div className="col-md-6" key={photoIndex}>
                                                        <img
                                                            src={`${SERVER}/uploads/news/${photo.originalName}`}
                                                            alt={`Image ${photoIndex + 1}`}
                                                            className="d-block w-100"
                                                            style={{
                                                                maxWidth: "100%",
                                                                height: "300px", // Adjust height
                                                                objectFit: "contain",
                                                            }}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {/* Carousel Controls */}
                                <div className="p-4">
                                    {data.filter((data) => data._id === article._id).flatMap(item => item.subphoto).length > 1 && (
                                        <>
                                            <button
                                                className="carousel-control-prev"
                                                type="button"
                                                data-bs-target="#carouselExampleDark"
                                                data-bs-slide="prev"
                                            >
                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span className="visually-hidden">Previous</span>
                                            </button>
                                            <button
                                                className="carousel-control-next"
                                                type="button"
                                                data-bs-target="#carouselExampleDark"
                                                data-bs-slide="next"
                                            >
                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span className="visually-hidden">Next</span>
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>

                            
                        </div>
                        

                       
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={resetForm}>Close</button>
                    </div>
                </div>
            </div>

            </div>
        </div>
    )
}

export default AdminNews;