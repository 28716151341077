import { NavLink } from 'react-router-dom';
import logo from '../assets/logo/euodoo-dark.png';
// import { useToggle } from '../context/ToggleContext';
import { useState, useEffect } from 'react';

import menuBtn from "../assets/home/menu-outline.svg"


function AdminMenu  (){
    const [toggle, setToggle] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleDropdown = () => {
      setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown visibility
    };

    const handleToggleMenu = () => {
        setToggle(!toggle);
    }

    const handleLogout = () => {
     document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;"; 

     window.location.href = '/portal-login'; 
    }
      

    return(
        // <div className="admin-menu ">

        //     <NavLink id="menulinks" to='/upload' ><h5 id="textmenu">DASHBOARD</h5></NavLink>
        //     <NavLink id="menulinks" to='/schedule-admin'><h5 id="textmenu">SCHEDULE</h5></NavLink>
        //     <NavLink id="menulinks" to="#"><h5 id="textmenu">OUR TEAM</h5></NavLink>
        //     <NavLink id="menulinks" to="#"><h5 id="textmenu">HOME</h5></NavLink>
        //     <NavLink id="menulinks" to='/news-admin'><h5 id="textmenu">News</h5></NavLink>
        //     <NavLink id="menulinks" to="#"><h5 id="textmenu">PARTNERS</h5></NavLink>
        //     <NavLink id="menulinks" to='/upload-announcement'><h5 id="textmenu">ANNOUNCEMENT</h5></NavLink>
        //     <NavLink id="menulinks" to="#"><h5 id="textmenu">CHAT</h5></NavLink>

          
        // </div>
        <>
            <div className={`sidebar d-flex p-4 flex-column ${toggle ? 'active' : ''}`}>
                <div className="sidebar-header d-flex align-items-center mb-3 justify-content-between" style={{ height: '70px' }}>
                <div className="logo">
                    <img src={logo} alt="" style={{ height: '50px' }} />
                </div>

                </div>
                <div className="links mb-auto">
                    <div className="link-divider mb-3">
                        <span className='px-3 fw-bold text-uppercase' style={{ fontSize: '12px' }}>Home</span>
            
                        <NavLink to="/home" className='px-3 d-flex flex-direction-column' activeclassname="active" style={{ padding: '13px' }}>
                        <div className="link-icon">
                            <i className="fa-solid fa-gauge"></i>
                        </div>
                        <p className="m-0">Dashboard</p>
                        </NavLink>
                    </div>


                    <div className="link-divider mb-3">
                    <span className='px-3 fw-bold text-uppercase' style={{ fontSize: '12px' }}>Manage</span>

                    <NavLink to="/upload-announcement" className='px-3 d-flex flex-direction-column' activeclassname="active" style={{ padding: '13px' }}>
                        <div className="link-icon">
                            <i className="fa-solid fa-bullhorn"></i>
                        </div>
                        <p className="m-0">Announcement</p>

                    </NavLink>

                    {/* <NavLink to="/news-admin" className='px-3 d-flex flex-direction-column' activeclassname="active" style={{ padding: '13px' }}>
                        <div className="link-icon">
                        <i className="fa-solid fa-newspaper"></i>
                        </div>
                        News and Articles
                    </NavLink> */}

                    <div className="admin-dropdown-container mt-2 mb-2">
                        <button onClick={handleDropdown} className="admin-dropdown-button">
                            <div className="link-icon">
                                <i className="fa-solid fa-newspaper"></i>
                            </div>
                            <p className="m-0">News and Articles</p>
                        </button>

                        {/* Dropdown Menu */}
                        {isDropdownOpen && (
                            <div className="admin-dropdown-menu">
                            <ul>
                                <li>
                                    <NavLink
                                        to="/news-admin"
                                        end
                                        className="px-3 d-flex flex-direction-column"
                                        activeclassname="active"
                                        style={{ padding: "13px" }}
                                    >
                                        All Articles
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/news-admin/create"
                                        className="admin-dropdown-item"
                                        activeclassname="active"
                                    >
                                        Create Article
                                    </NavLink>
                                </li>
                                
                            </ul>
                            </div>
                        )}
                        </div>

                        <NavLink to="/schedule-admin" className='px-3 d-flex flex-direction-column' activeclassname="active" style={{ padding: '13px' }}>
                            <div className="link-icon">
                                <i className="fa-solid fa-calendar-days"></i>
                            </div>
                            <p className="m-0">Schedule</p>
                        </NavLink>

                    </div>

                </div>
                <hr/>
                <div className='links'>
                    <NavLink className='px-3 d-flex flex-direction-column' onClick={handleLogout} activeclassname="active" style={{ padding: '13px' }}>
                        <div className="link-icon">
                            <i className="fa-solid fa-right-from-bracket"></i>
                        </div>
                        
                        <p className="m-0">Logout</p>
                    </NavLink>
                </div>
        </div>

        <div className={`admin-menu-btn ${toggle ? 'active' : ''}`}>
            <img className='img-fluid' src={menuBtn} alt="X" onClick={handleToggleMenu} />
        </div>
      </>
    )
} 

export default AdminMenu;

// source : https://www.youtube.com/watch?v=YH63fnqG7F0
// using mysql: https://www.youtube.com/watch?v=dLXSJdTK9QI