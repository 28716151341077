import NewsBanner from "../assets/page-banners/news-banner.png";
import puvmpImg from "../assets/news/launching/pm_jeepney_launching1.png";
import puvmpImg2 from "../assets/news/launching/bus.png";
import puvmpImg3 from "../assets/news/launching/w_erap.png";
import dummy300x400 from "../assets/test/300x400.png";
import dummy300x300 from "../assets/test/300x300.png";
import Newsdb from "../data/newsdb";
import { NavLink } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';


const Article = () => {
    const images = [
        { index: 1, image: puvmpImg },
        { index: 2, image: puvmpImg2 },
        { index: 3, image: puvmpImg3 },
      ];

    return (
        <div id="article" className="article">
            <div className="page-header article-header" style={{ backgroundImage: `url(${puvmpImg})` }}>
                <div className="article-overlay"></div>
                <h1 className="fw-bold text-center">PM Jeepney Launching</h1>
                <div className="sub-heading">
                    <p>Published: October 2, 2024</p>
                </div>
            </div>
            <div className="container">
                <div className="article-body p-100">
                    <p>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                        Accusantium dicta qui magnam nobis. Voluptate dolore consectetur 
                        tempora sed reprehenderit nihil. Perspiciatis accusantium minus 
                        vel est assumenda voluptas dicta mollitia illo perferendis ad magnam, 
                        accusamus maiores sed, amet officiis eius quasi quidem sit ipsum fuga! 
                        Aperiam soluta debitis voluptate quae natus qui nemo tempora, officiis 
                        ducimus reprehenderit, autem quis eaque, sapiente nam magni sint maiores 
                        neque voluptatibus animi esse hic corrupti. Consequuntur, adipisci impedit. 
                        Quod rerum laboriosam eligendi excepturi sit reprehenderit repellendus quos! 
                        Pariatur dolor maxime esse cupiditate nobis neque quia, autem necessitatibus 
                        ducimus accusantium explicabo dolorem exercitationem? Commodi, accusantium 
                        alias!
                    </p>
                    <div className="right-image-article mb-3 flex-md-row flex-column">
                        <div className="article-text-wrapper d-flex flex-column me-md-3">
                            <p>
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                                Accusantium dicta qui magnam nobis. Voluptate dolore consectetur 
                                tempora sed reprehenderit nihil. Perspiciatis accusantium minus 
                                vel est assumenda voluptas dicta mollitia illo perferendis ad magnam, 
                                accusamus maiores sed, amet officiis eius quasi quidem sit ipsum fuga! 
                                Aperiam soluta debitis voluptate quae natus qui nemo tempora, officiis 
                                ducimus reprehenderit, autem quis eaque, sapiente nam magni sint maiores 
                                neque voluptatibus animi esse hic corrupti. Consequuntur, adipisci impedit. 
                                Quod rerum laboriosam eligendi excepturi sit reprehenderit repellendus quos! 
                                Pariatur dolor maxime esse cupiditate nobis neque quia, autem necessitatibus 
                                ducimus accusantium explicabo dolorem exercitationem? Commodi, accusantium 
                                alias!
                            </p>
                            <p>
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                                Accusantium dicta qui magnam nobis. Voluptate dolore consectetur 
                                tempora sed reprehenderit nihil. Perspiciatis accusantium minus 
                                vel est assumenda voluptas dicta mollitia illo perferendis ad magnam, 
                                accusamus maiores sed, amet officiis eius quasi quidem sit ipsum fuga! 
                                Aperiam soluta debitis voluptate quae natus qui nemo tempora, officiis 
                                ducimus reprehenderit, autem quis eaque, sapiente nam magni sint maiores 
                                neque voluptatibus animi esse hic corrupti. Consequuntur, adipisci impedit. 
                                Quod rerum laboriosam eligendi excepturi sit reprehenderit repellendus quos! 
                                Pariatur dolor maxime esse cupiditate nobis neque quia, autem necessitatibus 
                                ducimus accusantium explicabo dolorem exercitationem? Commodi, accusantium 
                                alias!
                            </p>
                        </div>
                        <div className="article-image-wrapper mx-auto">
                            <img src={dummy300x400} alt="" />
                        </div>
                    </div>
                    <div className="left-image-article mb-3 flex-md-row-reverse flex-column">
                        <div className="article-text-wrapper d-flex flex-column ms-md-3">
                            <p>
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                                Accusantium dicta qui magnam nobis. Voluptate dolore consectetur 
                                tempora sed reprehenderit nihil. Perspiciatis accusantium minus 
                                vel est assumenda voluptas dicta mollitia illo perferendis ad magnam, 
                                accusamus maiores sed, amet officiis eius quasi quidem sit ipsum fuga! 
                                Aperiam soluta debitis voluptate quae natus qui nemo tempora, officiis 
                                ducimus reprehenderit, autem quis eaque, sapiente nam magni sint maiores 
                                neque voluptatibus animi esse hic corrupti. Consequuntur, adipisci impedit. 
                                Quod rerum laboriosam eligendi excepturi sit reprehenderit repellendus quos! 
                                Pariatur dolor maxime esse cupiditate nobis neque quia, autem necessitatibus 
                                ducimus accusantium explicabo dolorem exercitationem? Commodi, accusantium 
                                alias!
                            </p>
                            <p>
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                                Accusantium dicta qui magnam nobis. Voluptate dolore consectetur 
                                tempora sed reprehenderit nihil. Perspiciatis accusantium minus 
                                vel est assumenda voluptas dicta mollitia illo perferendis ad magnam, 
                                accusamus maiores sed, amet officiis eius quasi quidem sit ipsum fuga! 
                                Aperiam soluta debitis voluptate quae natus qui nemo tempora, officiis 
                                ducimus reprehenderit, autem quis eaque, sapiente nam magni sint maiores 
                                neque voluptatibus animi esse hic corrupti. Consequuntur, adipisci impedit. 
                                Quod rerum laboriosam eligendi excepturi sit reprehenderit repellendus quos! 
                                Pariatur dolor maxime esse cupiditate nobis neque quia, autem necessitatibus 
                                ducimus accusantium explicabo dolorem exercitationem? Commodi, accusantium 
                                alias!
                            </p>
                        </div>
                        <div className="article-image-wrapper mx-auto">
                            <img src={dummy300x300} alt="" />
                        </div>
                    </div>
                    <p>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                        Accusantium dicta qui magnam nobis. Voluptate dolore consectetur 
                        tempora sed reprehenderit nihil. Perspiciatis accusantium minus 
                        vel est assumenda voluptas dicta mollitia illo perferendis ad magnam, 
                        accusamus maiores sed, amet officiis eius quasi quidem sit ipsum fuga! 
                        Aperiam soluta debitis voluptate quae natus qui nemo tempora, officiis 
                        ducimus reprehenderit, autem quis eaque, sapiente nam magni sint maiores 
                        neque voluptatibus animi esse hic corrupti. Consequuntur, adipisci impedit. 
                        Quod rerum laboriosam eligendi excepturi sit reprehenderit repellendus quos! 
                        Pariatur dolor maxime esse cupiditate nobis neque quia, autem necessitatibus 
                        ducimus accusantium explicabo dolorem exercitationem? Commodi, accusantium 
                        alias!
                    </p>
                    <p>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                        Accusantium dicta qui magnam nobis. Voluptate dolore consectetur 
                        tempora sed reprehenderit nihil. Perspiciatis accusantium minus 
                        vel est assumenda voluptas dicta mollitia illo perferendis ad magnam, 
                        accusamus maiores sed, amet officiis eius quasi quidem sit ipsum fuga! 
                        Aperiam soluta debitis voluptate quae natus qui nemo tempora, officiis 
                        ducimus reprehenderit, autem quis eaque, sapiente nam magni sint maiores 
                        neque voluptatibus animi esse hic corrupti. Consequuntur, adipisci impedit. 
                        Quod rerum laboriosam eligendi excepturi sit reprehenderit repellendus quos! 
                        Pariatur dolor maxime esse cupiditate nobis neque quia, autem necessitatibus 
                        ducimus accusantium explicabo dolorem exercitationem? Commodi, accusantium 
                        alias!
                    </p>
                </div>

                <div className="carousel-wrapper mb-5 mx-auto p-100">
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={2}
                        // onSlideChange={() => console.log('slide change')}
                        // onSwiper={(swiper) => console.log(swiper)}
                        >
                        <SwiperSlide><img src={puvmpImg} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={puvmpImg2} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={puvmpImg3} alt="" /></SwiperSlide>
                    </Swiper>
                </div>

                <div className="article-accordion p-100">
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Accordion Item #1
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Accordion Item #2
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Accordion Item #3
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-nav d-flex justify-content-between align-items-center">
                    <NavLink to='/news'>Back</NavLink> 
                    <NavLink to='/news'>More News</NavLink> 
                </div>
            </div>
        </div>
    );
};

export default Article;
