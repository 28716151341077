import AVT from "../assets/services-page/afcs/AVT Track Recorder TR-100C.png"
import IndoorCameraWithMicrophone from "../assets/services-page/afcs/Analog HD Indoor IR Camera w_ Microphone.png"
import IndoorCamera from "../assets/services-page/afcs/Analog HD Indoor IR Camera.png"
import OutdoorCamera from "../assets/services-page/afcs/Analog HD Outdoor IR Camera (Rear Camera).png"
import LCDMonitor from "../assets/services-page/afcs/LCD Monitor.png"
import SDCard from "../assets/services-page/afcs/SD Card.png"
import LedRed from "../assets/services-page/afcs/16x5 LED Signage (Red).png"
import LedAmber from "../assets/services-page/afcs/20x6 LED Signage (Amber).png"
import Router from "../assets/services-page/afcs/WiFi Router.png"
import Cable from "../assets/services-page/afcs/cable.png"

export const recorder = [
    {
        name: "AVT Track Recorder TR-100C",
        img: AVT,
        specs: [
            "ARM Cortex A9 with Embedded Linux OS",
            "4 channel real-time SD card video recording",
            "H.264 & JPEG multi-stream encoding",
            "Video Resolution: 720P / 960H/ D1/ CIF"
        ]
    },
    {
        name: "Analog HD Indoor IR Camera w/ Microphone",
        img: IndoorCameraWithMicrophone,
        specs: [
            "1/3 inch Sony CMOS",
            "2.8mm 1.3MP 1280 x 960P",
            "115 degrees viewing angle",
            "microphone"
        ]
    },
    {
        name: "Analog HD Indoor IR Camera",
        img: IndoorCamera,
        specs: [
            "1/3 inch Sony CMOS",
            "2.8mm 1.3MP 1280 x 960P",
            "115 degrees viewing angle"
        ]
    },
    {
        name: "Analog HD Outdoor IR Camera (Rear Camera)",
        img: OutdoorCamera,
        specs: [
            "1/3 inch Sony CMOS",
            "2.8mm 1.3MP 1280 x 960P",
            "115 degrees viewing angle"
        ]
    },
    {
        name: "LCD Monitor",
        img: LCDMonitor,
        specs: [
            "7 inch TFT LCD Wide Screen (16:9)",
            "Contrast Ratio: 300:1",
            "Resolution: 800 x 480",
            "8V-36V 10W",
            "Video invert/ mirror mode"
        ]
    },
    
];

export const accessories = [
    {
        name: "MicroSDXC 128GB (Sandisk / Transcend)",
        img: SDCard,
        specs: [
            "100 MB/s 667x",
            "Recording hours for 4 cameras: 77 hours"
        ]
    },
    {
        name: "CCTV Aviation Cables",
        img: Cable,
        specs: [
            "5 meters 4 PIN Aviation Cable",
            "10 meters 4 PIN Aviation Cable",
            "15 meters 4 PIN Aviation Cable",
            "20 meters 4 PIN Aviation Cable"
        ]
    },
    {
        name: "LED Signage (Red)",
        img: LedRed,
        specs: [
            "16x5"
        ]
    },
    {
        name: "LED Signage (Amber)",
        img: LedAmber,
        specs: [
            "20x6"
        ]
    },
    {
        name: "WiFi Router",
        img: Router,
        specs: [
            "ET610-LC4S CAT4 4G-LTE",
            "Industrial WiFi Router"
        ]
    }
]