import { useState, useEffect } from "react";
import axios from 'axios';
import { SERVER } from "../../keyvariable";

const AdminLogin =()=>{
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async(e) =>{
        e.preventDefault();

        try {
            const data = {
                username:username,
                password:password
            }
            const response = await axios.post(`${SERVER}login`, data);

            if(response.status === 200){
                document.cookie = `token=${response.data.token}; path=/`;
                window.location.href = '/upload-announcement';
            }
            
        } catch (error) {
            console.log(error);
            
        }
        
    }

    return(
        <div className="d-flex justify-content-center align-items-center" style={{minHeight:"100vh"}}>
           <div className="login-form shadow-sm mb-5 bg-body rounded">
                <form onSubmit={handleSubmit} >
                    <div className="mb-3">
                        <h2 className="text-center">Login</h2>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="username" className="form-label">Email address</label>
                        <input type="email" className="form-control" id="username" aria-describedby="emailHelp" name="username"  onChange={(e)=>setUsername(e.target.value)}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password</label>
                        <input type="password" className="form-control" id="password" name="password" onChange={(e)=>setPassword(e.target.value)}/>
                    </div>
                    <button type="submit" className="login-submit">Submit</button>
                </form>
           </div>
        </div>
    )
}

export default AdminLogin;