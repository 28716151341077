import timeline from "../assets/milestone/milestonetransparent.png";
import SirJepz from "../assets/team/jepzapata.png"
import SirAllen from "../assets/team/apzapata.png"
import SirAj from "../assets/team/arcastro.png"
import SirChris from "../assets/team/cmmorales.png"
import MsChristine from "../assets/team/cqmamaril.png"
import FadeInSection from "../components/FadeInSection";

import { useEffect } from "react";

const Aboutus = ()=>{
    useEffect(() => {
        const page = document.getElementById('aboutuspage');
        page.scrollIntoView({behavior:'smooth',block:'start'});
    });


//   SSL_CRT_FILE=./.cert/cert.pem SSL_KEY_FILE=./.cert/key.pem
    return(
        <div id="aboutuspage" className= "aboutuspage">
            <div className="page-header">
                <div className="container d-flex justify-content-center align-items-center flex-column">
                    <h1 className='fw-bold text-center'>About Us</h1>
                </div>
            </div>
            <div className="container p-100 text-justify px-2">
                <FadeInSection direction="bottom">

                    <div className="company-tagline text-center mt-5">
                        <h2 className="text-center m-0 fw-bolder fs-1">
                            BUILDING TECHNOLOGIES, <br />
                            INSPIRING INNOVATIONS
                        </h2>
                    </div>
                    <div className="about-introduction-bg mt-5">
                        <p className="m-0">
                            Euodoó Technologies, Inc. is the culmination of over fifteen (15) years of 
                            extensive experience in the Information Technology (IT) Solutions and Services 
                            industry. Formerly known as Innovare Tekhne, this spin-off company now 
                            specializes in Systems Integration (SI) products and IT-managed services.
                        </p>
                    </div>

                    <div className="about-introduction mt-5 mb-2">
                        <p className="mb-3">
                            Euodoó Tech offers a comprehensive range of IT solutions, focusing on key 
                            areas such as Fleet Management System, Automated Fare Collection System, 
                            Vulnerability Assessment and Penetration Testing, Anti-Money Laundering System, 
                            Visitor Management System, Event Management System, Human Resource Information 
                            System, customized software development, and outsourced IT solutions and services 
                            for both public and private entities. With a strong management team, highly skilled 
                            IT professionals, and dependable solution partners, Euodoó Tech takes pride in delivering 
                            exceptional technical expertise and competency. The company has secured accreditation 
                            from Dun & Bradstreet (D&B), confirming its compliance with the Vendor 
                            Integrity Access (VIA) requirements.
                        </p>
                        <p className="m-0">
                            Guided by the viewpoint, “Building Technologies. Inspiring Innovations,” Euodoó Tech 
                            employs a distinctive and strategic approach to understanding and assessing critical IT 
                            solutions that empower clients to achieve competitive edge. The company believes that the 
                            success and efficiency of any IT solution depend on the collaborative efforts of both 
                            the solution provider and the client. As a dedicated partner, Euodoó Tech remains responsive 
                            to the evolving technology landscape and the dynamic nature of the business environment.
                        </p>
                    </div>
                </FadeInSection>

                {/* <FadeInSection direction="bottom">
                    <div className="about-timeline">
                        <img src={timeline} alt="" />
                    </div>
                </FadeInSection> */}

                <div className="about-team overflow-hidden mt-5">
                    <h2 className="fw-bold text-center mb-5">Our Team</h2>

                    <div className="team-ceo">
                        <div className="row">
                            <div className="col-12 col-md-4 ceo-img d-flex justify-content-center align-items-center">
                                <FadeInSection direction="left">
                                    <div className="d-flex justify-content-center align-items-center user-select-none">
                                        <img src={SirJepz} alt="Julius Zapata" />
                                    </div>
                                </FadeInSection>
                            </div>

                            <div className="col-12 col-md-8">
                                <FadeInSection direction="right">
                                    <p className='text-justify'>
                                        Julius Evan P. Zapata is a highly skilled Information and Communications Technology (ICT) Specialist with over 20 years of experience in delivering outstanding IT solutions across diverse industries, including Oil and Gas, Housing, National Government Agencies, banking, and the private sector.
                                    </p>
                                    <p className='text-justify'>
                                        His extensive consultancy experience includes work with esteemed organizations such as the US Agency for International Development (USAID), Philippine Amusement and Gaming Corporation (PAGCOR), Bureau of Internal Revenue (BIR), Philippine National Oil Company Exploration Corporation, National Housing Authority (NHA), Bank of Florida, Rang-ay Bank, and The Straits Network.
                                    </p>
                                    <p className='text-justify'>
                                        He holds a Bachelor's degree in Computer Engineering and a Master's degree in Public Administration with a focus on Spatial Information Management, both earned from the University of the Philippines and ITC Netherlands.
                                    </p>
                                    <p className='text-justify'>
                                        Recognized for his exceptional technical proficiency, leadership, and professionalism, he has built a reputation as a trusted ICT Specialist. 
                                    </p>
                                </FadeInSection>

                            </div>
                        </div>
                    </div>
                    <div className="team-members my-5">
                    <FadeInSection direction="bottom">
                        <div className="row justify-content-around">
                            <div className="col-6 col-md-3 d-flex justify-content-center align-items-center flex-column">
                                <div className="members-img"><img src={SirAllen} alt="Alejandro Zapata" /></div>
                                <div className="members-label text-center mt-2">
                                    <div className="member-name">Alejandro P. Zapata</div>
                                    <div className="member-designation">Head, Admin and Operations</div>
                                </div>
                            </div>
                            <div className="col-6 col-md-3 d-flex justify-content-center align-items-center flex-column">
                                    <div className="members-img"><img src={SirAj} alt="Alein Jeth Castro" /></div>
                                <div className="members-label text-center mt-2">
                                    <div className="member-name">Alein-Jeth R. Castro</div>
                                    <div className="member-designation">Account Manager</div>
                                </div>
                            </div>
                            <div className="col-6 col-md-3 d-flex justify-content-center align-items-center flex-column">
                                    <div className="members-img"><img src={SirChris} alt="Christopher Morales" /></div>
                                <div className="members-label text-center mt-2">
                                    <div className="member-name">Christopher M. Morales</div>
                                    <div className="member-designation">Head, Finance and Accounting</div>
                                </div>
                            </div>
                            <div className="col-6 col-md-3 d-flex justify-content-center align-items-center flex-column">
                                    <div className="members-img"><img src={MsChristine} alt="Christine Mamaril" /></div>
                                <div className="members-label text-center mt-2">
                                    <div className="member-name">Christine Q. Mamaril</div>
                                    <div className="member-designation">VP for Sales and Marketing</div>
                                </div>
                            </div>

                        </div>
                            </FadeInSection>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default Aboutus;




