import { NavLink, useNavigate  } from "react-router-dom";
import { useEffect, useState } from "react";
import {SERVER} from '../keyvariable';
import axios from 'axios';
import moment from 'moment';

const News = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [featuredData, setFeaturedData] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const page = document.getElementById('news');
        page.scrollIntoView({behavior: 'smooth', block: 'start'});
       
    });
    useEffect(()=>{
        getData();
    },[])

    const getData = async() =>{
        try {
            const response = await axios.get(`${SERVER}main/newsList`);
            const newsList = response.data.newsList;
    
            const updatedNewsList = newsList.map(news => ({
                ...news,
                base64Image: `${SERVER}uploads/news/${news.image.originalName}` 
            }));

            const filteredNews = updatedNewsList.filter(data => data.status !== "draft");

            setData(filteredNews);
            

            const featured = filteredNews.filter(data=>data.category === "featured");
            setFeaturedData(featured);
            
            
        } catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false);
        }
    }

    const categoryCounts = data.reduce((acc, item) => {
        acc[item.category] = (acc[item.category] || 0) + 1; 
        return acc;
    }, {});
    
    const subcategoryCounts = data.reduce((acc, item) => {
        acc[item.subcategory] = (acc[item.subcategory] || 0) + 1;
        return acc;
    }, {});
    
    const categoriesToDisplay = ['modern_jeepney', 'cybersecurity', 'services']; 
    

    return (
        <div id="news" className="news">
            <div className="page-header news-header">
                <h1 className="fw-bold">Euodoo News</h1>
                <div className="sub-heading">
                    <p>Check the latest news about us!</p>
                </div>
            </div>
            <div className="container">
                <div className="news-filter">
                    <ul className="list-unstyled m-0 p-3 flex-wrap text-center">
                        <li>
                            <NavLink to="/news/category/all" className="d-flex align-items-center justify-content-center">All</NavLink>
                        </li>
                        <li>
                            <NavLink to="/news/category/modern_jeepney" className="d-flex align-items-center justify-content-center">Modern Jeepney</NavLink>
                        </li>
                        <li>
                            <NavLink to="/news/category/cyber_security" className="d-flex align-items-center justify-content-center">Cybersecurity</NavLink>
                        </li>
                        <li>
                            <NavLink to="/news/category/services" className="d-flex align-items-center justify-content-center">Services</NavLink>
                        </li>
                    
                    </ul>
                </div>

                <div className="featured-section my-5">
                    <div className="section-title d-flex justify-content-between align-items-end mb-4">
                        <h2 className="m-0">Featured News</h2>
                    </div>

                    <div className="row row-gap-2">
                    {
                        loading ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="spinner-border text-dark" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <>
                                {featuredData.length > 0 && (
                                    <div className="col-md-6 col-12">
                                        <div className="card big-card d-flex p-3 gap-2">
                                            <NavLink className="featured-title" to={`/news/article/${featuredData[0]._id}`}>
                                                <div className="card-image">
                                                    <img
                                                        src={featuredData[0].base64Image}
                                                        alt={featuredData[0].image.originalName}
                                                        className="rounded"
                                                    />
                                                </div>
                                            </NavLink>

                                            <div className="card-info">
                                                <span className="card-date fw-semibold">
                                                    {moment(featuredData[0].date).format('MMM DD, YYYY')}
                                                </span>
                                                <NavLink className="featured-title" to={`/news/article/${featuredData[0]._id}`}>
                                                    <h3 className="card-title m-0 fw-bold">{featuredData[0].title}</h3>
                                                </NavLink>
                                                <p className="card-description m-0 mt-2" style={{ fontSize: '15px' }}>
                                                    {featuredData[0].sections
                                                        .map((section) => {
                                                            const paragraphColumn = section.columns.find(
                                                                (column) => column.type === 'paragraph'
                                                            );
                                                            if (paragraphColumn) {
                                                                const content = paragraphColumn.content || '';
                                                                const descriptionWords = content.split(' ');
                                                                return (
                                                                    descriptionWords.slice(0, 36).join(' ') +
                                                                    (descriptionWords.length > 36 ? '...' : '')
                                                                );
                                                            }
                                                            return null;
                                                        })
                                                        .filter(Boolean)[0] || 'No description available'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {featuredData.length > 1 && (
                                    <div className="col-md-6 col-12 row-gap-2 d-flex justify-content-center align-items-center flex-column">
                                        {featuredData.slice(1, 3).map((item, index) => (
                                            <div
                                                className="featured-right-cards card p-3 d-flex flex-row-reverse gap-2"
                                                key={index}
                                            >
                                                <div className="card-image small-featured">
                                                    <NavLink className="featured-title" to={`/news/article/${item._id}`}>
                                                        <img
                                                            src={item.base64Image}
                                                            alt={item.image.originalName}
                                                            className="rounded"
                                                        />
                                                    </NavLink>
                                                </div>

                                                <div className="card-info small-featured">
                                                    <span className="card-date fw-semibold">
                                                        {moment(item.date).format('MMM DD, YYYY')}
                                                    </span>
                                                    <h3
                                                        className="card-title m-0 fw-bold"
                                                        style={{ textDecoration: 'none' }}
                                                    >
                                                        <NavLink className="featured-title" to={`/news/article/${item._id}`}>
                                                            {item.title.split(' ').slice(0, 3).join(' ') +
                                                                (item.title.split(' ').length > 3 ? '...' : '')}
                                                        </NavLink>
                                                    </h3>

                                                    <p className="card-description m-0 mt-2" style={{ fontSize: '15px' }}>
                                                        {featuredData[index + 1].sections
                                                            .map((section) => {
                                                                const paragraphColumn = section.columns.find(
                                                                    (column) => column.type === 'paragraph'
                                                                );
                                                                if (paragraphColumn) {
                                                                    const content = paragraphColumn.content || '';
                                                                    const descriptionWords = content.split(' ');
                                                                    return (
                                                                        descriptionWords.slice(0, 16).join(' ') +
                                                                        (descriptionWords.length > 16 ? '...' : '')
                                                                    );
                                                                }
                                                                return null;
                                                            })
                                                            .filter(Boolean)[0] || 'No description available'}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </>
                        )
                    }

                    </div>
                </div>

                <div className="category-section my-5">

                    <div className="row">

                        {categoriesToDisplay.map((category, index) => {
                            if (categoryCounts[category] > 0 || subcategoryCounts[category] > 0) { 
                                return (
                                    <div className="section mb-5" key={index}>
                                        <div className="section-title d-flex justify-content-between align-items-end mb-4">
                                            <h2 className="m-0">
                                            {
                                                category === 'modern_jeepney'?
                                                `Modern Jeepney News`
                                                : category === 'cybersecurity'?
                                                `Cybersecurity News`
                                                :
                                                `Services News`
                                            }
                                            </h2>
                                            <NavLink to={`/news/category/${category}`} className="btn featuredtitlebutn fw-bold">View All</NavLink>
                                        </div>

                                        <div className="row">
                                            {data.filter(item => item.category === category || item.subcategory === category).slice(0, 4).map((rowData, index) => {
                                                const truncatedDescription = rowData.sections
                                                    .map((section) => {
                                                        const paragraphColumn = section.columns.find((column) => column.type === 'paragraph');
                                                        if (paragraphColumn) {
                                                            const content = paragraphColumn.content;
                                                            const descriptionWords = (content || '').split(' ');
                                                            return descriptionWords.slice(0, 16).join(' ') + (descriptionWords.length > 16 ? '...' : '');
                                                        }
                                                        return null;
                                                    })
                                                    .filter(Boolean)[0];

                                                return (
                                                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 gap-2" key={index}>
                                                        <div className="card modern p-3 mb-2">
                                                            <div className="card-image">
                                                                <NavLink className="featured-title" to={`/news/article/${rowData._id}`}>
                                                                    <img src={rowData.base64Image} alt={rowData.image.originalName} className="rounded"/>
                                                                </NavLink>
                                                            </div>
                                                            <div className="card-info mt-2">
                                                                <span className="card-date fw-semibold">{moment(rowData.date).format('MMM DD, YYYY')}</span>
                                                                <NavLink className="featured-title" to={`/news/article/${rowData._id}`}>
                                                                    <h3 className="card-title m-0 fw-bold">
                                                                        {
                                                                            rowData.title.split(' ').slice(0, 3).join(' ') + (rowData.title.split(' ').length > 3 ? '...' : '')
                                                                        }
                                                                    </h3>
                                                                </NavLink>
                                                                <p className="card-description mt-2 mb-0" style={{fontSize:'15px'}}>
                                                                    {truncatedDescription}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            }
                            return null; 
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default News;
