import ActiveRides from "../assets/services-page/euoride/Euoride Template Screenshots/Active Rides.png"
import BookingTracking from "../assets/services-page/euoride/Euoride Template Screenshots/Booking Tracking.png"
import ChooseLocation from "../assets/services-page/euoride/Euoride Template Screenshots/Choose Location.png"
import ChooseVendor from "../assets/services-page/euoride/Euoride Template Screenshots/Choose Vendor.png"
import ContactUs from "../assets/services-page/euoride/Euoride Template Screenshots/Contact Us.png"
import CreateAccount1 from "../assets/services-page/euoride/Euoride Template Screenshots/Create Account 1.png"
import CreateAccount2 from "../assets/services-page/euoride/Euoride Template Screenshots/Create Account 2.png"
import FindingDriver from "../assets/services-page/euoride/Euoride Template Screenshots/Finding Driver.png"
import Home from "../assets/services-page/euoride/Euoride Template Screenshots/Home.png"
import Login from "../assets/services-page/euoride/Euoride Template Screenshots/Login.png"
import LoyaltyPoints from "../assets/services-page/euoride/Euoride Template Screenshots/Loyalty Points.png"
import ProfileDetails from "../assets/services-page/euoride/Euoride Template Screenshots/Profile Details.png"
import SavedLocations from "../assets/services-page/euoride/Euoride Template Screenshots/Saved Locations.png"
import TripSummary from "../assets/services-page/euoride/Euoride Template Screenshots/Trip Summary.png"
import Wallet from "../assets/services-page/euoride/Euoride Template Screenshots/Wallet.png"

const steps = [
    {
        image: CreateAccount1,
        title: "Create Account",
        content: "If you don't have an account yet, sign up first in the sign up page."
    },
    {
        image: CreateAccount2,
        title: "Input your details",
        content: "Fill up the fields with your information."
    },
    {
        image: Login,
        title: "Login",
        content: "Login with your account to start using Euoride!"
    },
    {
        image: Home,
        title: "Home",
        content: "Choose your service taxi booking, motor delivery or suv delivery."
    },
    {
        image: ChooseLocation,
        title: "Choose Location",
        content: "Select your starting and end destination."
    },
    {
        image: ChooseVendor,
        title: "Choose Vendor",
        content: "Choose a taxi provider group."
    },
    {
        image: TripSummary,
        title: "Trip Summary",
        content: "You can view the trip details and select payment method."
    },
    {
        image: FindingDriver,
        title: "Finding Driver",
        content: "Drivers will accept your booking in this stage."
    },
    {
        image: ActiveRides,
        title: "My Rides",
        content: "You can view your active and past rides in this menu."
    },
    {
        image: ProfileDetails,
        title: "Profile Details",
        content: "Update your email address and mobile number"
    },
    {
        image: SavedLocations,
        title: "Saved Locations",
        content: "Manage your saved locations. Add, update or delete your locations."
    },
    {
        image: BookingTracking,
        title: "Booking Tracking",
        content: "Track where the driver is in booking tracking."
    },
    {
        image: LoyaltyPoints,
        title: "Loyalty Points",
        content: "View your accumulated loyalty points."
    },
    {
        image: Wallet,
        title: "Wallet",
        content: "Manage your wallet. Top-up credits and view your recent transactions."
    },
    {
        image: ContactUs,
        title: "Contact Us",
        content: "If you have any concerns, reach out to us!"
    }
];

export default steps;