import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { SERVER } from "../../keyvariable";
import moment from 'moment';
import { NavLink, useParams } from "react-router-dom";

const NewsCategory = () =>{

    const [data, setData] = useState([]);
    const [articleName, setArticleName] = useState("");
    const [filteredData, setFilteredData] = useState([]);

    const { category } = useParams();  


    useEffect(()=>{
        fetchData();
    }, []);

    const fetchData = async() => {
        try {
            const response = await axios.get(`${SERVER}main/newsList`);
            const newsList = response.data.newsList;
    
            const updatedNewsList = newsList.map(news => ({
                ...news,
                base64Image: `${SERVER}uploads/news/${news.image.originalName}` 
            }));

            const filteredNews = updatedNewsList.filter(data => data.status !== "draft");

            const articleCategory = filteredNews.filter(data => {
                if (category.toLowerCase() === "all") {
                    return true; 
                }
                const categoryMatch = data.category && data.category.toLowerCase() === category.toLowerCase();
                const subCategoryMatch = data.subcategory && data.subcategory.toLowerCase() === category.toLowerCase();
                return categoryMatch || subCategoryMatch;
            });
            

            const sortNewest = articleCategory.sort((a, b) => new Date(b.date) - new Date(a.date));
    
            setData(articleCategory);
            setFilteredData(sortNewest);

            
            

        } catch (error) {
            console.log(error);
            
        }
    }

    const sortArticles = (selected) =>{
        const sortedData = [...data];

        if (selected === "newest") {
            sortedData.sort((a, b) => new Date(b.date) - new Date(a.date));
        } else if (selected === "oldest") {
            sortedData.sort((a, b) => new Date(a.date) - new Date(b.date));
        } else if (selected === "az") {
            sortedData.sort((a, b) => a.title.localeCompare(b.title));
        } else if (selected === "za") {
            sortedData.sort((a, b) => b.title.localeCompare(a.title));
        }

        setFilteredData(sortedData);
        
    }

    const searchArticle = (e) =>{
        e.preventDefault();

        if(articleName !== ""){
            const filteredData = data.filter(data=> data.title.toLowerCase().includes(articleName.toLowerCase()));
            setFilteredData(filteredData);
        }
        else {
            setFilteredData(data);
        }
          
        
    }


    return(
        <>
            <div className="page-header">
                <div className="container d-flex justify-content-center align-items-center flex-column">
                    <h1 className='fw-bold text-center'>
                    {
                        category === 'all' ? 
                            "News Articles"
                        :
                        category === 'modern_jeepney' ?
                            "Modern Jeepney"
                        :
                        category === 'cyber_security' ?
                            "Cyber Security"
                        :
                            "Services"
                            
                    }
                    </h1>
                </div>
            </div>

            <div className="container my-5">
                <div className="nav-articles mb-5">
                    <h3>Displaying {filteredData.length} Article Results</h3>

                    <div className="d-flex align-items-center justify-content-center flex-column flex-md-row gap-2">
                            <form onSubmit={searchArticle} className="me-md-auto mr-auto">
                                <div className="d-flex gap-2">
                                    <input className="form-control" onChange={(e)=>setArticleName(e.target.value)} value={articleName} />
                                    <button className="btn btn-secondary text-white" type="submit"><i className="fas fa-search"></i></button>
                                </div>
                                
                            </form>
                        <div className="d-flex">
                            <div className="me-md-auto d-flex align-items-center gap-2">
                                <p className="m-0">Sort:</p>
                                <select className="form-select" onChange={(e) => sortArticles(e.target.value)}>
                                    <option value="newest">Newest Articles First</option>
                                    <option value="oldest">Oldest Articles First</option>
                                    <option value="az">A to Z (Alphabetical)</option>
                                    <option value="za">Z to A (Alphabetical)</option>
                                    
                                </select>
                            </div>
                        </div>
                    </div>

                    {/* <div class="input-group">
                        <div class="form-outline" data-mdb-input-init>
                            <input id="form1" type="search"  className="form-control" />
                            <label className="form-label" for="form1">Search</label>
                        </div>
                        <button id="search-button" type="button" className="btn btn-primary">
                            <i className="fas fa-search"></i>
                        </button>
                    </div> */}
                </div>

                <div className="article-cards my-5">
                    {
                        filteredData.length === 0 ? 
                            <div className="d-flex justify-content-center">
                                <h3>No articles found</h3>
                            </div>

                        :

                        Array.from({ length: Math.ceil(filteredData.length / 4) }, (_, rowIndex) => {
                            const rowData = filteredData.slice(rowIndex * 4, rowIndex * 4 + 4);
                            
                            return (
                                <div className="row" key={rowIndex}>
                                    {rowData.map((item, index) => {
                                        const truncatedDescription = item.sections
                                            .map((section) => {
                                                const paragraphColumn = section.columns.find((column) => column.type === 'paragraph');
                                                if (paragraphColumn) {
                                                    const content = paragraphColumn.content;
                                                    const descriptionWords = (content || '').split(' ');
                                                    return descriptionWords.slice(0, 16).join(' ') + (descriptionWords.length > 16 ? '...' : '');
                                                }
                                                return null;
                                            })
                                            .filter(Boolean)[0];

                                        return (
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-12 gap-2 news-category" key={index}>
                                                <div className="card p-3 mb-2">
                                                    <div className="card-image">
                                                        <NavLink className="featured-title" to={`/news/article/${item._id}`}>
                                                            <img src={item.base64Image} alt={item.image.originalName} className="rounded" />
                                                        </NavLink>
                                                    </div>
                                                    <div className="card-info mt-2">
                                                        <span className="card-date fw-semibold">
                                                            {moment(item.date).format('MMM DD, YYYY')}
                                                        </span>
                                                        <NavLink className="featured-title" to={`/news/article/${item._id}`}>
                                                            <h3 className="card-title m-0 fw-bold">
                                                                {item.title.split(' ').slice(0, 4).join(' ') + (item.title.split(' ').length > 3 ? '...' : '')}
                                                            </h3>
                                                        </NavLink>
                                                        <p className="card-description m-0" style={{ fontSize: '15px' }}>
                                                            {truncatedDescription}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })
                    }
                </div>
                
            </div>



        </>
    )
}

export default NewsCategory;