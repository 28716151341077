import { useState } from "react"
import PageBanner from "../../assets/services-page/vapt/VAPT Page Banner.png"
import Cert1 from "../../assets/services-page/vapt/dict 1.png"
import Cert2 from "../../assets/services-page/vapt/dict 2.png"
import { NavLink } from "react-router-dom"


const VAPT = () => {
    const [activeCertificate, setActiveCertificate] = useState('')

    const handleToggle = (certificate) => {
        setActiveCertificate(certificate)
    }

    const handleClose = () => {
        setActiveCertificate(null);
    };

    return (
        <>
            <div className="vapt">
                <div className="page-header position-relative">
                    <img src={PageBanner} className="page-banner" alt="" />
                    <div className="container d-flex justify-content-center align-items-center flex-column">
                        <h1 className='fw-bold text-center'>Vulnerability Assessment and Penetration Testing</h1>
                        {/* <div className="sub-heading">
                            <p className="m-0">
                                Euodoó Technologies’ Vulnerability Assessment and Penetration Testing (VAPT) is an 
                                essential part of cybersecurity strategy. It identifies and mitigates vulnerabilities, 
                                protecting sensitive information and enhancing overall security.
                            </p>
                        </div> */}
                    </div>
                </div>

                <div className="vapt-content-container">
                    <div className="vapt-content-text d-flex">
                        <div className="container text-center p-5">
                            <h3 className="fw-semibold">Vulnerability Assessments Tailored to Your Needs</h3>
                            <p>
                                Euodoó Technologies’ Vulnerability Assessment and Penetration Testing (VAPT) is an 
                                essential part of cybersecurity strategy. It identifies and mitigates vulnerabilities, 
                                protecting sensitive information and enhancing overall security.
                            </p>
                        </div>
                    </div>

                    <div className="vapt-certificate-container container py-5">
                        <div className="text-center">
                            <h3 className="fw-semibold">DICT Certification</h3>
                        </div>

                        <div className="certificates d-flex justify-content-center align-items-center gap-5 mt-5 flex-md-row flex-column">
                          <div className="certificate">
                                <img
                                    src={Cert1}
                                    alt="Certificate 1"
                                    onClick={() => handleToggle("cert1")}
                                    className={activeCertificate === "cert1" ? "active" : ""}
                                />
                            </div>
                            <div className="certificate">
                                <img
                                    src={Cert2}
                                    alt="Certificate 2"
                                    onClick={() => handleToggle("cert2")}
                                    className={activeCertificate === "cert2" ? "active" : ""}
                                />
                            </div>
                        </div>

                        {activeCertificate && (
                            <div className="vapt-overlay" onClick={handleClose}>
                                <div className="vapt-overlay-content d-flex">
                                    <img
                                        src={activeCertificate === "cert1" ? Cert1 : Cert2}
                                        alt={activeCertificate}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="text-center bg-dark text-light p-4">
                    <div className="container">
                            <h4 className="fw-bold">Get a Free Consultation!</h4>
                            <NavLink to='/contactus' className="btn home-cta fw-semibold">Request Now</NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VAPT;