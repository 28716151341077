import AccidentReconstruction from "../../assets/services-page/fms/accident-reconstruction.jpg"
import DriverBehavior from "../../assets/services-page/fms/driver-behavior.jpg"
import FuelMonitoring from "../../assets/services-page/fms/fuel-monitoring.jpg"
import History from "../../assets/services-page/fms/history.jpg"
import Incident from "../../assets/services-page/fms/incident.jpg"
import RealTimeTracking from "../../assets/services-page/fms/real-time-tracking.jpg"
import VehicleSpeed from "../../assets/services-page/fms/vehicle-speed.jpg"
import Violation from "../../assets/services-page/fms/violation.jpg"

import FMB920 from "../../assets/services-page/fms/FMB 920.png"
import FMB125 from "../../assets/services-page/fms/FMB 125.png"
import FMB003 from "../../assets/services-page/fms/FMB 003.png"
import PageBanner from "../../assets/services-page/fms/FMS Page Banner.png"
import { NavLink } from "react-router-dom"


const FMS = () => {
    return (
        <>
            <div className="fms">
                <div className="page-header">
                    <img src={PageBanner} className="page-banner" alt="FMS Page Banner" />
                    <div className="container d-flex justify-content-center align-items-center flex-column">
                        <h1 className='fw-bold text-center'>Fleet Management System</h1>
                        <div className="sub-heading">
                            <p className="m-0">
                                
                            </p>
                        </div>
                    </div>
                </div>

                <div className="fms-content-container container my-5">
                    <div className="fms-header d-flex flex-column p-100">
                        <h3 className="text-center">
                            Monitor, Manage, and Maximize Your Fleet's Potential
                        </h3>
                        <p className="text-center">
                            Euodoó Technologies’ Fleet Management System (AFCS) is composed to enhance vehicle productivity. 
                            Our advanced telematic devices make it easy to gather asset data and transmit information to the
                            platform. These devices can track and monitor vehicles in various aspects, including but not 
                            limited to:
                        </p>
                    </div>

                    <div className="fms-features d-flex justify-content-center align-items-center gap-3 flex-wrap my-5">
                        <div className="feature-box">
                            <p className="fw-bold m-0">Real-time Tracking</p>
                            <img src={RealTimeTracking} alt="Real-time tracking" />
                            <span className="feature-info fw-semibold p-5">Monitor with confidence with real-time tracking on your vehicle.</span>
                        </div>

                        <div className="feature-box">
                            <p className="fw-bold m-0">History</p>
                            <img src={History} alt="History" />
                            <span className="feature-info fw-semibold p-5">Access detailed history reports for your vehicle’s past activities.</span>
                        </div>

                        <div className="feature-box">
                            <p className="fw-bold m-0">Vehicle Speed</p>
                            <img src={VehicleSpeed} alt="Vehicle Speed" />
                            <span className="feature-info fw-semibold p-5">Track your vehicle’s speed in real-time and view past data.</span>
                        </div>

                        <div className="feature-box">
                            <p className="fw-bold m-0">Fuel Monitoring</p>
                            <img src={FuelMonitoring} alt="Fuel Monitoring" />
                            <span className="feature-info fw-semibold p-5">Monitor fuel usage and ensure optimal fuel efficiency.</span>
                        </div>

                        <div className="feature-box">
                            <p className="fw-bold m-0">Driver Behavior</p>
                            <img src={DriverBehavior} alt="Driver in car" />
                            <span className="feature-info fw-semibold p-5">Analyze driving habits to improve safety and reduce risks.</span>
                        </div>

                        <div className="feature-box">
                            <p className="fw-bold m-0">Accident Reconstruction</p>
                            <img src={AccidentReconstruction} alt="Traffic cone" />
                            <span className="feature-info fw-semibold p-5">Reconstruct accidents with detailed data for thorough analysis.</span>
                        </div>

                        <div className="feature-box">
                            <p className="fw-bold m-0">Incidents</p>
                            <img src={Incident} alt="Police Line" />
                            <span className="feature-info fw-semibold p-5">Track incidents involving your vehicle and manage follow-up actions.</span>
                        </div>

                        <div className="feature-box">
                            <p className="fw-bold m-0">Violations</p>
                            <img src={Violation} alt="Ticket in car" />
                            <span className="feature-info fw-semibold p-5">Get real-time alerts for any traffic violations your vehicle may incur.</span>
                        </div>
                    </div>

                    <div className="telematic-devices container">
                        <h2 className="text-uppercase text-center fw-bold my-5">telematic devices</h2>
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="telematic-img col-12 col-md-6">
                                <img src={FMB920} className="img-fluid" alt="FMB 920 device" />
                            </div>
                            <div className="telematic-info-box p-3 col-12 col-md-6 d-flex justify-content-center flex-column">
                                <h4>FMB 920</h4>
                                <p className="m-0">
                                    The Teltonika FMB920 is a compact GPS tracker offering real-time tracking, 
                                    crash detection, and Bluetooth support for wireless accessories. With features 
                                    like geofencing and easy installation, it’s ideal for cars, motorcycles, and other 
                                    mobile assets, providing a cost-effective solution for vehicle security and 
                                    fleet management.
                                </p>
                            </div>
                        </div>
                        <div className="row flex-row-reverse d-flex justify-content-center align-items-center">
                            <div className="telematic-img col-12 col-md-6">
                                <img src={FMB125} className="img-fluid" alt="FMB 125 Device" />
                            </div>
                            <div className="telematic-info-box p-3 col-12 col-md-6 d-flex justify-content-center flex-column">
                                <h4>FMB 125</h4>
                                <p className="m-0">
                                    The Teltonika FMB125 is a versatile GPS tracker with external antenna support 
                                    for improved connectivity and accuracy. It features real-time tracking, geofencing, 
                                    and Bluetooth support, along with multiple input/output options for integrating additional 
                                    accessories. With its wide voltage range, it’s perfect for vehicles, motorcycles, 
                                    and heavy machinery, offering robust performance for fleet management and asset tracking.
                                </p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="telematic-img col-12 col-md-6">
                                <img src={FMB003} className="img-fluid" alt="FMB 003 Device" />
                            </div>
                            <div className="telematic-info-box p-3 col-12 col-md-6 d-flex justify-content-center flex-column">
                                <h4>FMB 003</h4>
                                <p className="m-0">
                                    The Teltonika FMB003 is an ultra-compact GPS tracker designed for 
                                    direct OBDII connectivity, making installation quick and hassle-free. 
                                    It offers real-time tracking, engine diagnostics, and crash detection 
                                    via a built-in accelerometer. With geofencing, Bluetooth support, and 
                                    compatibility with a wide range of vehicles, it’s an ideal choice for 
                                    fleet management, driver monitoring, and personal vehicle tracking.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="demo-cta text-center bg-dark text-light p-4">
                    <div className="container">
                            <h4 className="fw-bold mb-3">Request a Free Quote</h4>
                            <NavLink to='/contactus' className="btn home-cta fw-semibold">Get Quote</NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FMS;