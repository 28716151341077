import milestone from "../assets/milestone/milestonetransparent.png";

import strategy from "../assets/whatwedo/howitstarted.png";
import bayadcarddunsdict from "../assets/logo/bayadcarddunsdict.png";

import ltfrbLogo from "../assets/logo/LTFRB_Seal.svg"
import dictLogo from "../assets/logo/dict.png"

import closeIcon from "../assets/home/close-outline.svg"
import closeIconDark from "../assets/home/close-outline-dark.svg"

// all in a day news/article
import fms from "../assets/news/article/fms1.jpg";
import fms2 from "../assets/news/article/fms2-t.png";
import mainpic from "../assets/announcement/PASANGMASDA/mainpics.jpg";
import clients from "../data/clientdb";
import partner from "../data/partnerdb";

// -----------services--------------------------
import EMSCard from "../assets/home/ems-card.png";
import FMSCard from "../assets/home/fms-card.png";
import PUVMPCard from "../assets/home/puvmp-card.png";
import VAPTCard from "../assets/home/vapt-card.png";

// We are grateful
import WhatWeOffer from "../assets/home/what-we-offer.png";

// Partners Logos
import TesdaLogo from "../assets/clients/tesda.png";
import TbpLogo from "../assets/clients/tpb-logo1.png";
import HomesLogo from "../assets/clients/homes1.png";
import BaliwagLogo from "../assets/clients/baliwag_transit.png";
import PNOCLogo from "../assets/clients/pnoc1.png";
import PrimerLogo from "../assets/clients/primer1.png";
import PTCLogo from "../assets/clients/ptc1.png";
import PjsSupportLogo from "../assets/clients/pjs1.png";
import Geodata from "../assets/clients/Geodata.png";
import Hikvision from "../assets/clients/Hikvision.png";
import Packetworkx from "../assets/clients/Packetworx.svg";
import UMBusiness from "../assets/clients/UM Business Inc.png";


// -------------video----------------------------
import euodooVideo from "../assets/video/euodoo.mp4";
import tabletEuodooVideo from "../assets/video/tablet-euodoo.mp4";
import mobileEuodooVideo from "../assets/video/mobile-euodoo.mp4";

import { Link, NavLink } from "react-router-dom";

import { useState,useEffect } from "react";
import axios from 'axios';
import { SERVER } from "../keyvariable";

import FadeInSection from "../components/FadeInSection";

const Homepage = ()=>{

    const [announcementcount, setannouncementcount] = useState([]);
    const [showOverlay, setShowOverlay] = useState(false);

    const [videoSrc, setVideoSrc] = useState(euodooVideo);

    // Announcement Overlay Script Starts
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isFading, setIsFading] = useState(false); 
  
    const announcements = [
      {
        heading: "Euodoó Technologies Reaffirms DICT Certification for Vulnerability Assessment and Penetration Testing (VAPT)",
        body: "Euodoó Technologies holds a longstanding DICT certification for Vulnerability Assessment and Penetration Testing (VAPT), reaffirming our commitment to top-tier cybersecurity. Our expertise ensures that clients’ systems are protected against evolving threats, maintaining the highest standards in vulnerability mitigation.",
        images: [
          { src: dictLogo, alt: 'DICT Logo' },
        ]
      },
      {
        heading: "Euodoó Technologies Recognized as an Accredited GPS Provider by LTFRB",
        body: "Euodoó Technologies is recognized by the Land Transportation Franchising and Regulatory Board (LTFRB) as an official GPS service provider. This accreditation allows us to offer advanced GPS tracking solutions that enhance fleet management and compliance with transportation regulations, ensuring a safer and more efficient transport system.",
        images: [
          { src: ltfrbLogo, alt: 'LTFRB Logo' },
        ]
      }
    ];
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setIsFading(true);
        
        setTimeout(() => {
          setCurrentSlide((prevSlide) => (prevSlide + 1) % announcements.length);
          setIsFading(false); 
        }, 300);
      }, 5000);
  
      return () => clearTimeout(timer); 
    }, [currentSlide, announcements.length]);
  
    const handleDotClick = (index) => {
      setIsFading(true);
      setTimeout(() => {
        setCurrentSlide(index);
        setIsFading(false);
      }, 300);
    };

    // Announcement Overlay Script Ends

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 767) {
                setVideoSrc(mobileEuodooVideo); // Mobile breakpoint
            } else if (window.innerWidth <= 991 && window.innerWidth >= 768) {
                setVideoSrc(tabletEuodooVideo); // Tablet breakpoint
            } else {
                setVideoSrc(euodooVideo); // Larger screens
            }
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Announcement Overlay

    useEffect(() => {
        // Check localStorage for the last seen date
        const lastSeenDate = localStorage.getItem("overlayLastSeen");
        const today = new Date().toDateString(); // Get the current date as a string (e.g., "Mon Jan 01 2025")

        if (lastSeenDate !== today) {
            setShowOverlay(true); // Show the overlay if it hasn't been seen today
        }
    }, []);

    const handleShowOverlay = () => {
        setShowOverlay(false);
        const today = new Date().toDateString(); // Save today's date as the last seen date
        localStorage.setItem("overlayLastSeen", today);
    };
    // Announcement Overlay Ends

    
    useEffect(()=>{
        axios.get(SERVER).then(res=>{
            setannouncementcount(res.data);
        }).catch(err => console.log(err))

    })

    const filtercolumn = ()=>{
        setannouncementcount(announcementcount.filter(announce => announce.status !== "inactive"))
    }
    return (
        <>
            {
                showOverlay ? (
                    <div className="overlay-announcement-container d-flex justify-content-center align-items-center">
                        <div className="overlay-announcement p-5">
                            <img
                                className="overlay-close"
                                width="25px"
                                height="25px"
                                src={closeIconDark}
                                alt="close-btn"
                                onClick={handleShowOverlay}
                            />
                            <div className="overlay-content h-100 d-flex justify-content-between align-items-center flex-column">
                                <div className={`fade-content h-100 d-flex justify-content-between align-items-center flex-column`}>
                                    <div className="overlay-heading text-center">
                                        <h2 className="fs-4 fw-bold">{announcements[currentSlide].heading}</h2>
                                    </div>
                                    <div className="overlay-images d-flex gap-2">
                                        {announcements[currentSlide].images.map((img, index) => (
                                            <img key={index} src={img.src} alt={img.alt} />
                                        ))}
                                    </div>
                                    <div className="overlay-body">
                                        <p>{announcements[currentSlide].body}</p>
                                    </div>
                                </div>
                                <div className="overlay-indicators d-flex justify-content-center gap-2 mt-3">
                                    {announcements.map((_, index) => (
                                        <span
                                            key={index}
                                            className={`dot ${index === currentSlide ? 'active' : ''}`}
                                            onClick={() => handleDotClick(index)}
                                        ></span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }

            <section className="hero">
            <div className="video-container">
                <video 
                    src={videoSrc} 
                    className="position-relative start-50 translate-middle-x" 
                    autoPlay 
                    playsInline 
                    loop 
                    muted
                ></video>
            </div>
            </section>
            
            <FadeInSection direction="bottom">
                <section id="home" className="header-bg container">
                    <div className="home-text text-center">
                        <h3 className="text-uppercase buildingtech fw-bold fs-2" id="building">Building  Technologies, <br/>Inspiring Innovations.</h3>
                        <p className="mx-md-5 fs-5 hometextp">
                            Euodoó Technologies, Inc. is committed to delivering services grounded in excellence and innovation. 
                            We prioritize the needs of both potential and existing clients while fostering long-term business partnerships.
                        </p>
                        <NavLink to='aboutus' className="btn fw-semibold home-cta">Our Story</NavLink>
                        {/* <div className="divimg">
                            <img src={milestone} className="milestonepic img-fluid" alt="s2018"/>
                        </div> */}
                    </div>
                </section>
            </FadeInSection>

            <section id="services" className="what-we-offer py-5 overflow-hidden">
                <div className="container">
                    <FadeInSection direction="bottom">
                        <div className="section-header text-center mb-5">
                            <h3 className="text-uppercase text-white fw-bold fs-2">Explore Our Solutions</h3>
                            <p className="mx-auto text-white">
                                Euodoó Tech offers comprehensive IT solutions and technical consulting services, 
                                meticulously designed to meet the specific requirements of various business functions 
                                and industry sectors. Our expertise spans a wide range of areas, ensuring that we can 
                                effectively support your organization’s challenges and drive operational success.
                            </p>
                            {/* <NavLink to='/' className="btn fw-semibold">Learn More</NavLink> */}
                        </div>
                    </FadeInSection>
                    <div className="offer-tiles d-flex justify-content-center align-items-center">
                        <div className="row">
                            <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column">
                                <FadeInSection direction="left">
                                    <div className="offer-tile mt-5 mb-5 p-3">
                                        <img src={FMSCard} alt="FMS Service Feature" />
                                        <div className="offer-text">
                                            <div className="offer-title mt-3">
                                                <h5 className="fs-3 fw-bold">
                                                    Fleet Management Made Easy
                                                </h5>
                                            </div>
                                            <div className="offer-description">
                                                <p>
                                                    Real-Time Tracking and Monitoring, Fuel,
                                                    Management and Monitoring, Route Replay 
                                                    Vehicle Maintenance and Repair Scheduling 
                                                    Driver's Behavior Monitoring.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </FadeInSection>

                                <FadeInSection direction="left">
                                    <div className="offer-tile p-3">
                                        <img src={EMSCard} alt="EMS Service Feature" />
                                        <div className="offer-text">
                                            <div className="offer-title mt-3">
                                                <h5 className="fs-3 fw-bold">
                                                    Game-changing Event Platform
                                                </h5>
                                            </div>
                                            <div className="offer-description">
                                                <p>
                                                    Streamline your event planning, organization, and execution. 
                                                    Whether virtual, hybrid, or onsite, we've got you covered. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </FadeInSection>
                            </div>

                            <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column">
                                <FadeInSection direction="right">
                                    <div className="offer-tile mb-5 p-3 mt-5 mt-md-0">
                                        <img src={PUVMPCard} alt="PUVMP Service Feature" />
                                        <div className="offer-text">
                                            <div className="offer-title mt-3">
                                                <h5 className="fs-3 fw-bold">
                                                    PUVMP Automated Fare Collection System
                                                </h5>
                                            </div>
                                            <div className="offer-description">
                                                <p>
                                                    System utilizes real-time GPS
                                                    distance-based fare computation; Calculates 
                                                    transactions and statistics at the desired time.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </FadeInSection>

                                <FadeInSection direction="right">
                                    <div className="offer-tile mb-3 p-3">
                                        <img src={VAPTCard} alt="VAPT Service Feature" />
                                        <div className="offer-text">
                                            <div className="offer-title mt-3">
                                                <h5 className="fs-3 fw-bold">
                                                    VAPT Suite: Secure Your Digital World
                                                </h5>
                                            </div>
                                            <div className="offer-description">
                                                <p>
                                                    Risk Detection, Threat Mitigation, Data Protection, Network Security, 
                                                    Compliance Assurance, Peace of Mind
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </FadeInSection>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
                <section className="connect-with-us py-5">
                    <div className="extended-background">
                        <div className="container connect-content d-flex justify-content-center align-items-center flex-column text-white text-center">
                            <FadeInSection direction="bottom">
                                <div className="section-header mb-3">
                                    <h3 className="fw-bold text-uppercase fs-2">Connect With Us</h3>
                                    <p>We're here for you. You can book an appointment <br /> or leave us a message, and we'll get back to you soon!</p>
                                </div>
                                <div className="connect-cta d-flex gap-3 justify-content-center align-items-center">
                                    <NavLink to='schedule' className='btn fw-semibold'>Schedule a Meeting</NavLink>
                                    <NavLink to='contactus' className='btn fw-semibold'>Send a Message</NavLink>
                                </div>
                            </FadeInSection>
                        </div>
                    </div>
                </section>                            

            <section id="partner">
                <div className="our-strong-partnership py-5 container">
                    <FadeInSection direction="bottom">
                        <div className="text-center pb-5">
                            <h3 className="text-uppercase clientheadtext fw-bold fs-2">
                                Organizations and Companies We Serve            
                            </h3>
                            <p className="text-center mx-auto">
                                Euodoó Technologies, Inc. is committed to providing tailored solutions 
                                that improve operational efficiency and foster innovation, ensuring your 
                                IT infrastructure aligns with your business goals.
                            </p>
                        </div>
                    </FadeInSection>

                    <div className="wrapper">
                        <div className="slider">
                            <div className="slide-track">
                            
                                <div className="slide">
                                    <div className="slide-bg">
                                        <img src={partner[20]} className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div>
                                <div className="slide">
                                    <div className="slide-bg">
                                        <img src={partner[21]} className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div>
                                <div className="slide">
                                    <div className="slide-bg">
                                        <img src={partner[22]} className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div>
                                <div className="slide">
                                    <div className="slide-bg">
                                        <img src={partner[0]} className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div>
                                <div className="slide">
                                    <div className="slide-bg">
                                        <img src={partner[1]} className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div>
                                <div className="slide">
                                    <div className="slide-bg">
                                        <img src={partner[2]} className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div>
                                <div className="slide">
                                    <div className="slide-bg famoko">
                                        <img src={partner[3]} height='90px' className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div>
                                <div className="slide">
                                    <div className="slide-bg">
                                        <img src={partner[4]} className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div>
                                <div className="slide">
                                    <div className="slide-bg hino">
                                        <img src={partner[5]} height='90px' className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div>
                                <div className="slide">
                                    <div className="slide-bg">
                                        <img src={partner[6]} className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div>
                                <div className="slide">
                                    <div className="slide-bg">
                                        <img src={partner[7]} className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div>
                                <div className="slide">
                                    <div className="slide-bg">
                                        <img src={partner[8]} className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div>
                                <div className="slide">
                                    <div className="slide-bg">
                                        <img src={partner[9]} className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div>
                                <div className="slide">
                                    <div className="slide-bg">
                                        <img src={partner[10]} className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div>
                                <div className="slide">
                                    <div className="slide-bg">
                                        <img src={partner[11]} className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div>
                                <div className="slide">
                                    <div className="slide-bg">
                                        <img src={partner[12]} className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div>
                                <div className="slide">
                                    <div className="slide-bg">
                                        <img src={partner[13]} className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div>
                                {/* <div className="slide">
                                    <div className="slide-bg">
                                        <img src={partner[14]} className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div>
                                <div className="slide">
                                    <div className="slide-bg">
                                        <img src={partner[15]} className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div> */}
                                <div className="slide">
                                    <div className="slide-bg">
                                        <img src={partner[16]} className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div>
                                <div className="slide">
                                    <div className="slide-bg">
                                        <img src={partner[17]} className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div>
                                <div className="slide">
                                    <div className="slide-bg">
                                        <img src={partner[18]} className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div>
                                <div className="slide">
                                    <div className="slide-bg">
                                        <img src={partner[19]} className="partnerimg d-block" alt="..."/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="spotlight-tiles pt-5">
                <div className="row organization-spotlight">
                    <div className="col-12 col-md-6 spotlight-tile p-5 d-flex justify-content-center align-items-start flex-column">
                        <FadeInSection direction="left">
                            <h3 className="fs-2 fw-bold text-uppercase">Strategic Partnerships</h3>
                            <p className="text-justify">
                                Organizations across diverse industries have chosen Euodoó Technologies, 
                                Inc. as their trusted IT partner. Our strong reputation is built on years of
                                delivering world-class service and addressing each technology need with care and precision. 
                            </p>
                        </FadeInSection>
                    </div>
                    <div className="col-12 col-md-6 spotlight-tile p-5 spotlight-img d-flex justify-content-center align-items-center flex-wrap">
                    <FadeInSection direction="right">
                        
                        <div className="partners-logos d-flex justify-content-center align-items-center flex-wrap gap-3">
                            <div className="partner-logo">
                                <img src={Geodata} alt="Geodata" />
                            </div>
                            <div className="partner-logo">
                                <img src={Hikvision} alt="HikVision" />
                            </div>
                            <div className="partner-logo">
                                <img src={Packetworkx} alt="Packetworx" />
                            </div>
                            <div className="partner-logo">
                                <img src={UMBusiness} alt="UM Business" />
                            </div>
                            <div className="partner-logo">
                                <img src={TesdaLogo} alt="Tesda" />
                            </div>
                            <div className="partner-logo">
                                <img src={TbpLogo} alt="TBP" />
                            </div>
                            <div className="partner-logo">
                                <img src={HomesLogo} alt="Homes" />
                            </div>
                            <div className="partner-logo">
                                <img src={BaliwagLogo} alt="Baliwag" />
                            </div>
                            <div className="partner-logo">
                                <img src={PNOCLogo} alt="PNOC" />
                            </div>
                            <div className="partner-logo">
                                <img src={PrimerLogo} alt="Primer" />
                            </div>
                            <div className="partner-logo">
                                <img src={PTCLogo} alt="PTC" />
                            </div>
                            <div className="partner-logo">
                                <img src={PjsSupportLogo} alt="PJS Support" />
                            </div>
                        </div>
                        </FadeInSection>

                    </div>
                </div>
                <div className="row news-spotlight">
                    <div className="col-12 col-md-6 p-0 spotlight-tile all-in-a-day">
                        <FadeInSection direction="left">
                            <img src={WhatWeOffer} alt="Euodoo Spotlight" />
                        </FadeInSection>
                    </div>
                    <div className="col-12 col-md-6 spotlight-tile p-5 d-flex justify-content-center align-items-end flex-column">
                        <FadeInSection direction="right">
                            <h3 className="fs-2 fw-bold text-uppercase">Learn More About Euodoó Technologies</h3>
                            <p>
                                Stay updated on our latest milestones and company events.
                            </p>
                            <NavLink to='/news' className="btn home-cta fw-semibold">Learn More</NavLink>
                        </FadeInSection>
                    </div>
                    
                </div>
            </div>
        </>
        
    )
}

export default Homepage;