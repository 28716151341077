import PageBanner from "../../assets/services-page/euoride/Euoride Page Banner.png";
import DriverFeature from "../../assets/services-page/euoride/Driver.png";
import ChevronLeft from "../../assets/services-page/euoride/chevron-back-outline.svg";
import ChevronRight from "../../assets/services-page/euoride/chevron-forward-outline.svg";
import EfficientBooking from "../../assets/services-page/euoride/efficient booking.svg";
import LowerFares from "../../assets/services-page/euoride/lower fares.svg";
import SmoothRide from "../../assets/services-page/euoride/smooth ride.svg";
import Steps from "../../data/euoride-steps.js";

import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import "swiper/css";
import "swiper/css/scrollbar";
import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";

const Euoride = () => {
    const [activeStep, setActiveStep] = useState(0);
    const swiperRef = useRef(null);
  
    const handleSlideChange = (swiper) => {
      const newIndex = swiper.activeIndex;
      if (newIndex !== activeStep) {
        setActiveStep(newIndex);
      }
    };
  
    const slideToIndex = (index) => {
      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.slideTo(index);
      }
    };
  
    const handlePrevClick = () => {
      const newIndex = Math.max(activeStep - 1, 0);
      slideToIndex(newIndex);
    };
  
    const handleNextClick = () => {
      const newIndex = Math.min(activeStep + 1, Steps.length - 1);
      slideToIndex(newIndex);
    };
  

    return (
        <>
            <div className="euoride">
                <div className="page-header">
                    <img src={PageBanner} className="page-banner" alt="Euoride Page Banner" />
                    <div className="container d-flex justify-content-center align-items-center flex-column">
                        <h1 className="fw-bold text-center">Euóride</h1>
                        <p className="euoride-sub-header">Taxi Booking App</p>
                    </div>
                </div>

                <div className="euoride-content-container">
                    <div className="euoride-content-text d-flex py-5">
                        <div className="container text-center">
                            <h3 className="fw-semibold">Maximize Impact, Minimize Effort</h3>
                            <p>
                                Euodoó Technologies’ Taxi Booking App has dedicated interfaces
                                for passengers, drivers, and admins. It also supports motor and
                                SUV deliveries, providing swift delivery for small parcels and
                                bulk items.
                            </p>
                        </div>
                    </div>

                    <div className="app-features container d-flex justify-content-center align-items-center pb-5 gap-3 flex-wrap">
                        <div className="app-feature p-3">
                            <img src={EfficientBooking} alt="a car and a girl" className="img-fluid" />
                            <p className="m-0 fw-semibold">Efficient Booking</p>
                        </div>
                        <div className="app-feature p-3">
                            <img src={SmoothRide} alt="girl on a bike enjoying" className="img-fluid" />
                            <p className="m-0 fw-semibold">Smooth Ride</p>
                        </div>
                        <div className="app-feature p-3">
                            <img src={LowerFares} alt="celebrating man and woman" className="img-fluid" />
                            <p className="m-0 fw-semibold">Lower Fares</p>
                        </div>
                    </div>

                    <div className="client-app">
                        <div className="container py-5">
                            <h3 className="text-center fw-bold mb-0 text-white fst-italic fs-2">
                                Euóride App
                            </h3>

                            <div className="phone-container mx-auto position-relative mt-4">
                                <img
                                    src={Steps[activeStep].image}
                                    alt={Steps[activeStep].title}
                                    className="img-fluid phone-image"
                                />
                                <img
                                    src={ChevronLeft}
                                    className="chev-left user-select-none"
                                    alt="previous"
                                    onClick={handlePrevClick}
                                />
                                <img
                                    src={ChevronRight}
                                    className="chev-right user-select-none"
                                    alt="next"
                                    onClick={handleNextClick}
                                />
                            </div>

                            <div className="swiper-description text-center my-3 mx-auto">
                                <p className="m-0 py-1 px-2 text-white w-auto">{Steps[activeStep].content}</p>
                            </div>

                            <div className="progress-wrapper px-3">

                            <Swiper
                                ref={swiperRef}
                                spaceBetween={10}
                                centeredSlides={true} // Center the active slide
                                grabCursor={true}
                                scrollbar={{ draggable: true }}
                                modules={[Scrollbar]}
                                // onSlideChange={(swiper) => setActiveStep(swiper.activeIndex)}
                                onSlideChange={handleSlideChange}
                                className="progress-container pb-3"
                                breakpoints={{
                                    1200: { // Viewport width 769px and above
                                    slidesPerView: 5,
                                    },
                                    1080: {
                                        slidesPerView: 4,
                                    },
                                    470: { // Viewport width between 470px and 768px
                                    slidesPerView: 2,
                                    },
                                    0: { // Viewport width below 470px
                                    slidesPerView: 1,
                                    },
                                }}
                                >
                                {Steps.map((step, index) => (
                                    <SwiperSlide
                                    key={index}
                                    className={`step-box p-2 ${activeStep === index ? "active" : ""}`}
                                    onClick={() => slideToIndex(index)} // Move to the clicked slide
                                    >
                                    <div className="step-title">
                                        <p className="m-0 text-center">{step.title}</p>
                                    </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                            </div>
                        </div>
                    </div>

                    <div className="euoride-feature">
                        <div className="container d-flex flex-column justify-content-center align-items-center gap-5 py-5">
                            <div className="euoride-content-text text-center">
                                <h3>Driver App</h3>
                                <p className="text-justify">
                                    The Euóride Driver App is a comprehensive and intuitive
                                    platform that enables drivers to efficiently manage rides,
                                    optimize earnings, and provide reliable service. It offers
                                    real-time ride requests, GPS navigation, and automatic fare
                                    calculations. By streamlining trip management and offering
                                    incentives, the app helps drivers maximize their earnings
                                    while maintaining a high level of service quality.
                                </p>
                            </div>

                            <div className="driver-feature user-select-none">
                                <img src={DriverFeature} className="img-fluid" alt="driver app on a mobile view" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center bg-dark text-light p-4">
                    <div className="container">
                            <h4 className="fw-bold">Learn more about Euoride</h4>
                            <NavLink to='/contactus' className="btn home-cta fw-semibold">Inquire Now</NavLink>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Euoride;
