import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import {SERVER} from './keyvariable.js';


import Home from './pages/home';
import News from './pages/newsandupdates';
import NewsFilter from './pages/NewsFilter';
import AFCS from './pages/article/AFCS.jsx';
import EMS from './pages/article/EMS.jsx';
import FMS from './pages/article/FMS.jsx';
import VAPT from './pages/article/VAPT.jsx';
import Euoride from './pages/article/Euoride.jsx';

// Article Pages
import Article from './pages/Article';
import Article2 from './pages/ArticleLayout.jsx';

import Career from './pages/career';
import Notfound from './pages/notfound';
import Aboutus from './pages/aboutus';
import Teampage from './pages/teampage';
import Newsdetail from './pages/newsdetail';
import Service from './pages/servicespage';
import Coverage from './pages/coverage';
import Form from './pages/form';
import Eventmanagement from './pages/eventmanagement';
import Chat from './components/chattest';
import ScrollToTop from './components/ScrollToTop.jsx';
import Schedule from './pages/admin pages/schedule';
import ScheduleClient from './pages/admin pages/schedule_client';
import Uploadannounce from './pages/admin pages/uploadannouncement';
import Guidefms from './pages/article/guidetofms.js';
import Fms from './pages/article/fmsarticle';
import Phcvs from './pages/article/phcvsarticle';
import Announcementdetail from './pages/article/announcementdetail';
// import Tester from "./pages/admin pages/testerdisplayimage";

import LightLayout from './layouts/LightLayout';
import DefaultLayout from './layouts/DefaultLayout';
import AdminLogin from './pages/admin pages/admin_login.jsx';
import AdminNews from './pages/admin pages/news_admin.jsx';
import CreateArticle from './pages/admin pages/create_article.jsx';
import NewsCategory from './pages/news category/newsCategory.jsx';


// const useAuth = () => {
//   const [isAuthenticated, setIsAuthenticated] = useState(null);

//   useEffect(() => {
//     const checkAuthentication = () => {
//       const tokenExists = document.cookie.includes('token');
//       setIsAuthenticated(tokenExists);
//     };
    
//     checkAuthentication();
//   }, []);

//   return isAuthenticated;
// };

// const ProtectedRoute = ({ element }) => {
//   const isAuthenticated = useAuth();

//   if (isAuthenticated === null) {
//     return (
//       <div className="spinner-container">
//         <div className="spinner-border" role="status">
//           <span className="visually-hidden">Loading...</span>
//         </div>
//       </div>
//     );
//   }
//   return isAuthenticated ? element : <Navigate to="/portal-login" />;
// };

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? match[2] : null;
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = getCookie('token');
  
      if (!token) {
        // console.error("Token not found");
        setIsAuthenticated(false);
        return;
      }
  
      try {
        const response = await axios.get(`${SERVER}auth`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.status === 200) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error("Authentication failed:", error);
        if (error.response && (error.response.status === 403 || error.response.status === 401)) {
          console.error("Authentication failed:", error);
          document.cookie = "token=; Max-Age=0; path=/";
          setIsAuthenticated(false); 
        }
      }
    };
  
    checkAuthentication();
  }, []);

  return isAuthenticated;
};



const ProtectedRoute = ({ element }) => {
  const isAuthenticated = useAuth();

  if (isAuthenticated === null) {
    return (
      <div className="spinner-container">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/portal-login" />;
  }

  return element;
};



function App() {
  const isAuthenticated = useAuth();
  return (
    <div className="container-fluid m-0 p-0 App ">
      <BrowserRouter>
      <ScrollToTop />
        <Routes>
          <Route path='/' element={<LightLayout><Home /></LightLayout>} />

          {/* Services Routes */}
          <Route path='/services/AFCS' element={<LightLayout><AFCS /></LightLayout>} />
          <Route path='/services/FMS' element={<LightLayout><FMS /></LightLayout>} />
          <Route path='/services/VAPT' element={<LightLayout><VAPT /></LightLayout>} />
          <Route path='/services/EMS' element={<LightLayout><EMS /></LightLayout>} />
          <Route path='/services/euoride' element={<LightLayout><Euoride /></LightLayout>} />


          <Route path='/news' element={<LightLayout><News /></LightLayout>} />
          <Route path='/news/filter' element={<LightLayout><NewsFilter /></LightLayout>} />

          {/* Article Pages */}
          <Route path='/news/article/:id' element={<LightLayout><Article /></LightLayout>} />
          <Route path='/news/article-layout' element={<LightLayout><Article2 /></LightLayout>} />
          <Route path='/news/category/:category' element={<LightLayout><NewsCategory/></LightLayout>}/>
          

          <Route path='/careers' element={<LightLayout><Career /></LightLayout>} />
          <Route path='/aboutus' element={<LightLayout><Aboutus /></LightLayout>} />
          <Route path='/team/:teamId' element={<DefaultLayout><Teampage /></DefaultLayout>} />
          <Route path='/news/:newsid' element={<DefaultLayout><Newsdetail /></DefaultLayout>} />
          <Route path='/services' element={<DefaultLayout><Service /></DefaultLayout>} />
          <Route path='/package' element={<DefaultLayout><Coverage /></DefaultLayout>} />
          <Route path='/contactus' element={<LightLayout><Form /></LightLayout>} />
          <Route path='/eventmanagement' element={<DefaultLayout><Eventmanagement /></DefaultLayout>} />
          <Route path='/announceDetail' element={<DefaultLayout><Announcementdetail /></DefaultLayout>} />
          <Route path='/fms-article/:articleid' element={<DefaultLayout><Fms /></DefaultLayout>} />
          <Route path='/guidetofms-article/:articleid' element={<DefaultLayout><Guidefms /></DefaultLayout>} />
          <Route path='/phcvs-article/:articleid' element={<DefaultLayout><Phcvs /></DefaultLayout>} />
          <Route path='/schedule' element={<DefaultLayout><ScheduleClient /></DefaultLayout>} />
          <Route path='*' element={<DefaultLayout><Notfound /></DefaultLayout>} />


          {/* admin */}
          <Route path='/portal-login'
              element={
                isAuthenticated === true ? (
                  <Navigate to="/upload-announcement" />
                ) : (
                  <DefaultLayout><AdminLogin /></DefaultLayout>
                )
              }
          />
          <Route path='/upload-announcement' element={<ProtectedRoute element={<Uploadannounce />}/>} />
          <Route path='/schedule-admin' element={<ProtectedRoute element={<Schedule />}/>} />
          <Route path='/news-admin' element={<ProtectedRoute element={<AdminNews/>}/>} />
          <Route path='/news-admin/create' element={<ProtectedRoute element={<CreateArticle/>}/>} />
          <Route path='/news-admin/edit/:articleid' element={<ProtectedRoute element={<CreateArticle />} />} />
        
        </Routes>
      </BrowserRouter>
      <Chat />
    </div>
  );
}

export default App;
