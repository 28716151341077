// export const SERVICE_ID = "service_b77495g"
// export const TEMPLATE_ID = "template_d0zi80oo"
// export const PUBLIC_KEY  = "wCzRPrKRG7w3cdxmS"
// // export const SERVER = "https://euodooserver.onrender.com/"
// export const SERVER = "http://localhost:4000/"
// export const ADMINKEY = "hfyr65nrjj769d1m3dsdhr"
// export const SCHEDULEKEY = "fgdhyhrthrdhthello1"
// export const EMAILTEST = "vivian614miranda@gmail.com"
// export const EMAILFROM = "euodoocustomercare@gmail.com"
// export const EMAILTO = "customercare@euodoo.com.ph"


export const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
export const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
export const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
export const SERVER = process.env.REACT_APP_SERVER;
export const ADMINKEY = process.env.REACT_APP_ADMIN_KEY;
export const SCHEDULEKEY = process.env.REACT_APP_SCHEDULE_KEY;
export const EMAILTEST = process.env.REACT_APP_EMAIL_TEST;
export const EMAILFROM = process.env.REACT_APP_EMAIL_FROM;
export const EMAILTO = process.env.REACT_APP_EMAIL_TO;
