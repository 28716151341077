import Navigation from '../components/navigation'; // Common navigation for other pages
import Footer from '../components/footer';

function DefaultLayout({ children }) {
  return (
    <>
      <Navigation theme="default" />
      {children}
      <Footer />
    </>
  );
}

export default DefaultLayout;