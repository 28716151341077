import NewsBanner from "../assets/page-banners/news-banner.png";
import puvmpImg from "../assets/news/launching/pm_jeepney_launching1.png";
import puvmpImg2 from "../assets/news/launching/bus.png";
import puvmpImg3 from "../assets/news/launching/w_erap.png";
import Newsdb from "../data/newsdb";
import { NavLink, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { SERVER } from "../keyvariable";
import axios from 'axios';
import moment from 'moment';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';


const Article = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const {id} = useParams();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await axios.get(`${SERVER}main/newsList`);
            const newsList = response.data.newsList;
    
            const updatedNewsList = newsList.map(news => ({
                ...news,
                base64Image: `${SERVER}uploads/news/${news.image.originalName}` 
            }));

            const findId = updatedNewsList.filter(data=>data._id === id);
    
            setData(findId);

            

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div id="article" className="article">
            {
                loading ?
                (
                    <div className="spinner-wrapper d-flex justify-content-center align-items-center">
                        <div className="spinner-border text-dark" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                )
                :
                data.map((rowData, index)=>(

                   <div key={index}>
                        <div className="page-header article-header" style={{ backgroundImage: `url(${rowData.base64Image})` }}>
                            <div className="article-overlay"></div>
                            <h1 className="fw-bold text-center">{rowData.title}</h1>
                            <div className="sub-heading">
                                <p className="m-0">Published: {moment(rowData.date).format('MMM DD, YYYY h:mm A')}</p>
                                <p className="m-0">By {rowData.author}</p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="article-body p-100">
                                {data.map((sectionItem, index) => (
                                    <div key={index}>
                                        {sectionItem.sections.map((section, sectionIndex) => (
                                            <div key={sectionIndex} className="right-image-article mb-3 d-flex align-items-center">
                                                {section.columns.map((column, columnIndex) => {
                                                    return (
                                                        column.type === 'paragraph' ? (
                                                            <div 
                                                                className="article-text-wrapper d-flex flex-column me-md-3 mb-3 flex-grow-1" 
                                                                key={columnIndex}
                                                                style={{ display: 'flex', alignItems: 'stretch' }}
                                                            >
                                                                <p 
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: column.content.replace(/\n/g, '<br />')
                                                                    }}
                                                                    style={{ margin: 0 }}
                                                                ></p>
                                                            </div>
                                                        ) : (
                                                            <div 
                                                                className="article-image-wrapper mx-auto mb-3 flex-grow-1" 
                                                                key={columnIndex}
                                                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch' }}
                                                            >
                                                                <img
                                                                    src={`${SERVER}/uploads/news/${column.image.originalName}`}
                                                                    alt={column.image.alt || `Image ${columnIndex + 1}`}
                                                                    style={{ maxWidth: '100%', height: 'auto', objectFit: 'contain' }}
                                                                />
                                                            </div>
                                                        )
                                                    );
                                                })}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>


                        {/*------------------------- carousell-------------------------------- */}

                        <div id="carouselExampleDark" className="carousel carousel-dark slide p-100">
                            {/* Carousel Indicators */}
                            {data.flatMap(item => item.subphoto).length > 1 && (
                                <div className="carousel-indicators p-100">
                                    {Array.from({ length: Math.ceil(data.flatMap(item => item.subphoto).length / 2) }).map((_, index) => (
                                        <button
                                            type="button"
                                            data-bs-target="#carouselExampleDark"
                                            data-bs-slide-to={index}
                                            className={index === 0 ? "active" : ""}
                                            aria-current={index === 0 ? "true" : "false"}
                                            aria-label={`Slide ${index + 1}`}
                                            key={index}
                                        ></button>
                                    ))}
                                </div>
                            )}

                            {/* Carousel Inner */}
                            <div className="carousel-inner">
                                {data.flatMap(item => item.subphoto).reduce((acc, photo, index, arr) => {
                                    // Group photos into pairs for each slide
                                    if (index % 2 === 0) {
                                        const pair = arr[index + 1] ? [photo, arr[index + 1]] : [photo];
                                        acc.push(pair);
                                    }
                                    return acc;
                                }, []).map((pair, index) => (
                                    <div
                                        className={`carousel-item ${index === 0 ? "active" : ""}`}
                                        key={index} // Unique key for each slide
                                        data-bs-interval="10000"
                                    >
                                        <div className="row justify-content-center">
                                            {pair.map((photo, photoIndex) => (
                                                <div className="col-md-6" key={photoIndex}>
                                                    <img
                                                        src={`${SERVER}/uploads/news/${photo.originalName}`}
                                                        alt={`Image ${photoIndex + 1}`}
                                                        className="d-block w-100"
                                                        style={{
                                                            maxWidth: "100%",
                                                            height: "300px", // Adjust height
                                                            objectFit: "contain",
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* Carousel Controls */}
                            <div className="p-4">
                                {data.flatMap(item => item.subphoto).length > 1 && (
                                    <>
                                        <button
                                            className="carousel-control-prev"
                                            type="button"
                                            data-bs-target="#carouselExampleDark"
                                            data-bs-slide="prev"
                                        >
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button
                                            className="carousel-control-next"
                                            type="button"
                                            data-bs-target="#carouselExampleDark"
                                            data-bs-slide="next"
                                        >
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>







                        {/*------------------------- accordion -------------------------------- */}

                        {/* <div className="article-accordion p-100">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Accordion Item #1
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Accordion Item #2
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Accordion Item #3
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        
                        {/* <div className="bottom-nav d-flex justify-content-between align-items-center">
                            <NavLink to='/news'>Back</NavLink> 
                            <NavLink to='/news'>More News</NavLink> 
                        </div> */}
                            
                        </div>
                   </div>


                ))


            }
            {/* 
            
            <div className="page-header article-header" style={{ backgroundImage: `url(${puvmpImg})` }}>
                <div className="article-overlay"></div>
                <h1 className="fw-bold text-center">PM Jeepney Launching</h1>
                <div className="sub-heading">
                    <p>Published: October 2, 2024</p>
                </div>
            </div>
            <div className="container">
                <div className="article-body p-100">
                    <p>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                        Accusantium dicta qui magnam nobis. Voluptate dolore consectetur 
                        tempora sed reprehenderit nihil. Perspiciatis accusantium minus 
                        vel est assumenda voluptas dicta mollitia illo perferendis ad magnam, 
                        accusamus maiores sed, amet officiis eius quasi quidem sit ipsum fuga! 
                        Aperiam soluta debitis voluptate quae natus qui nemo tempora, officiis 
                        ducimus reprehenderit, autem quis eaque, sapiente nam magni sint maiores 
                        neque voluptatibus animi esse hic corrupti. Consequuntur, adipisci impedit. 
                        Quod rerum laboriosam eligendi excepturi sit reprehenderit repellendus quos! 
                        Pariatur dolor maxime esse cupiditate nobis neque quia, autem necessitatibus 
                        ducimus accusantium explicabo dolorem exercitationem? Commodi, accusantium 
                        alias!
                    </p>
                    <p>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                        Accusantium dicta qui magnam nobis. Voluptate dolore consectetur 
                        tempora sed reprehenderit nihil. Perspiciatis accusantium minus 
                        vel est assumenda voluptas dicta mollitia illo perferendis ad magnam, 
                        accusamus maiores sed, amet officiis eius quasi quidem sit ipsum fuga! 
                        Aperiam soluta debitis voluptate quae natus qui nemo tempora, officiis 
                        ducimus reprehenderit, autem quis eaque, sapiente nam magni sint maiores 
                        neque voluptatibus animi esse hic corrupti. Consequuntur, adipisci impedit. 
                        Quod rerum laboriosam eligendi excepturi sit reprehenderit repellendus quos! 
                        Pariatur dolor maxime esse cupiditate nobis neque quia, autem necessitatibus 
                        ducimus accusantium explicabo dolorem exercitationem? Commodi, accusantium 
                        alias!
                    </p>
                    <p>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                        Accusantium dicta qui magnam nobis. Voluptate dolore consectetur 
                        tempora sed reprehenderit nihil. Perspiciatis accusantium minus 
                        vel est assumenda voluptas dicta mollitia illo perferendis ad magnam, 
                        accusamus maiores sed, amet officiis eius quasi quidem sit ipsum fuga! 
                        Aperiam soluta debitis voluptate quae natus qui nemo tempora, officiis 
                        ducimus reprehenderit, autem quis eaque, sapiente nam magni sint maiores 
                        neque voluptatibus animi esse hic corrupti. Consequuntur, adipisci impedit. 
                        Quod rerum laboriosam eligendi excepturi sit reprehenderit repellendus quos! 
                        Pariatur dolor maxime esse cupiditate nobis neque quia, autem necessitatibus 
                        ducimus accusantium explicabo dolorem exercitationem? Commodi, accusantium 
                        alias!
                    </p>
                    <p>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                        Accusantium dicta qui magnam nobis. Voluptate dolore consectetur 
                        tempora sed reprehenderit nihil. Perspiciatis accusantium minus 
                        vel est assumenda voluptas dicta mollitia illo perferendis ad magnam, 
                        accusamus maiores sed, amet officiis eius quasi quidem sit ipsum fuga! 
                        Aperiam soluta debitis voluptate quae natus qui nemo tempora, officiis 
                        ducimus reprehenderit, autem quis eaque, sapiente nam magni sint maiores 
                        neque voluptatibus animi esse hic corrupti. Consequuntur, adipisci impedit. 
                        Quod rerum laboriosam eligendi excepturi sit reprehenderit repellendus quos! 
                        Pariatur dolor maxime esse cupiditate nobis neque quia, autem necessitatibus 
                        ducimus accusantium explicabo dolorem exercitationem? Commodi, accusantium 
                        alias!
                    </p>
                </div>

                <div className="carousel-wrapper mb-5 mx-auto p-100">
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={2}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                        >
                        <SwiperSlide><img src={puvmpImg} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={puvmpImg2} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={puvmpImg3} alt="" /></SwiperSlide>
                    </Swiper>
                </div>

                <div className="article-accordion p-100">
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Accordion Item #1
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Accordion Item #2
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Accordion Item #3
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-nav d-flex justify-content-between align-items-center">
                    <NavLink to='/news'>Back</NavLink> 
                    <NavLink to='/news'>More News</NavLink> 
                </div>
            </div> */}
        </div>
    );
};

export default Article;
