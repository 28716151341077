import logo from "../assets/logo/euodoo-dark.png";
import logoWhite from "../assets/logo/euodoo-light.png";
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";

const Header = (props) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isTabletView, setIsTabletView] = useState(window.innerWidth <= 991);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 991);
    const [isDropdownOpen, setIsDropdownOpen] = useState({
        profile: false,
        services: false,
        updates: false,
    });

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 991);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        const handleResize = () => {
            setIsTabletView(window.innerWidth <= 991);
        };

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleLogoClick = () => {
        if (location.pathname === "/") {
            window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
            navigate("/");
        }
    };

    const handleToggle = () => {
        setIsMenuOpen(prev => !prev);
    };

    const closeMenu = () => {
        if (isTabletView) {
            const collapseElement = document.getElementById('navbarNavDropdown');
            if (collapseElement) {
                const bsCollapse = new window.bootstrap.Collapse(collapseElement);
                bsCollapse.hide();
                setIsMenuOpen(false);
            }
        }
    };

    const handleDropdownHover = (menu, isOpen) => {
        setIsDropdownOpen((prevState) => ({
            ...prevState,
            [menu]: isOpen,
        }));
    };

    return (
        <header>
            <nav className={`navigation fw-semibold navbar navbar-expand-lg ${isScrolled ? "fixed-top sticky " : ""}${isMenuOpen ? "toggled" : ""} ${props.theme === "light" ? "home-nav" : ""}`}>
                <div className="container">
                    <NavLink to="/" className="navbar-brand m-0" style={{ cursor: "pointer" }} onClick={handleLogoClick}>
                        <img 
                            src={props.theme === "default" 
                                ? logo 
                                : (isMenuOpen 
                                    ? logo 
                                    : (isScrolled 
                                        ? logo 
                                        : logoWhite)
                                )
                            }
                            style={{ cursor: "pointer", marginLeft: "-13px" }}
                            width="150px"
                            alt="Euodoo logo"
                        />
                    </NavLink>

                    <button 
                        onClick={handleToggle} 
                        className={`navbar-toggler ${isMenuOpen ? "menu-open" : ""}`} 
                        aria-controls="navbarNavDropdown" 
                        aria-expanded={isMenuOpen} 
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    
                    <div className={`collapse navbar-collapse justify-content-end ${isMenuOpen ? "show" : ""}`} id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            {/* Home Link */}
                            <li className="nav-item m-lg-2 mx-0">
                                <NavLink className={`nav-link ${isScrolled ? "sticky" : ""}${props.theme === "light" ? (isSmallScreen ? "text-black" : "text-white") : ""}`} to="/" onClick={() => {handleLogoClick(); closeMenu();}}>
                                    HOME
                                </NavLink>
                            </li>

                            {/* Our Profile Dropdown (Hover) */}
                            <li 
                                className="nav-item dropdown m-lg-2 mx-0" 
                                onMouseEnter={() => handleDropdownHover("profile", true)} 
                                onMouseLeave={() => handleDropdownHover("profile", false)}
                            >
                                <a
                                    className={`nav-link dropdown-toggle ${isScrolled ? "sticky" : ""}${props.theme === "light" ? (isSmallScreen ? "text-black" : "text-white") : ""} ${isDropdownOpen.profile ? "active-menu" : ""}`}
                                    id="profileDropdown"
                                    role="button"
                                    aria-expanded={isDropdownOpen.profile}
                                >
                                    OUR PROFILE
                                </a>
                                <ul className={`dropdown-menu ${isDropdownOpen.profile ? "show" : ""}`} aria-labelledby="profileDropdown">
                                    <li>
                                        <NavLink className="dropdown-item" to="/aboutus" onClick={closeMenu}>About Us</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item" to="/careers" onClick={closeMenu}>Careers</NavLink>
                                    </li>
                                </ul>
                            </li>

                            {/* Services Dropdown (Hover) */}
                            <li 
                                className="nav-item dropdown m-lg-2 mx-0" 
                                onMouseEnter={() => handleDropdownHover("services", true)} 
                                onMouseLeave={() => handleDropdownHover("services", false)}
                            >
                                <a
                                    className={`nav-link dropdown-toggle ${isScrolled ? "sticky" : ""}${props.theme === "light" ? (isSmallScreen ? "text-black" : "text-white") : ""} ${isDropdownOpen.services ? "active-menu" : ""}`}
                                    id="servicesDropdown"
                                    role="button"
                                    aria-expanded={isDropdownOpen.services}
                                >
                                    SERVICES
                                </a>
                                <ul className={`dropdown-menu ${isDropdownOpen.services ? "show" : ""}`} aria-labelledby="servicesDropdown">
                                    <li>
                                        <NavLink className="dropdown-item" to="/services/AFCS" onClick={closeMenu}>Automated Fare Collection System</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item" to="/services/FMS" onClick={closeMenu}>Fleet Management System</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item" to="/services/VAPT" onClick={closeMenu}>Vulnerability Assessment and Penetration Testing</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item" to="/services/EMS" onClick={closeMenu}>Event Management System</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item" to="/services/euoride" onClick={closeMenu}>Taxi Booking App (Euóride)</NavLink>
                                    </li>
                                </ul>
                            </li>

                            {/* Updates */}
                            <li className="nav-item m-lg-2 mx-0">
                                <NavLink className={`nav-link ${isScrolled ? "sticky" : ""}${props.theme === "light" ? (isSmallScreen ? "text-black" : "text-white") : ""}`} to="/news" onClick={() => {handleLogoClick(); closeMenu();}}>
                                    UPDATES
                                </NavLink>
                            </li>

                            {/* Contact Us Link */}
                            <li className="nav-item m-lg-2 me-lg-0 mx-0 contact-us-btn d-flex justify-content-center align-items-center ms-lg-5">
                                <NavLink className={`${isScrolled ? "sticky" : ""} text-white px-3`} to="/contactus" onClick={closeMenu}>
                                    CONTACT US
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
