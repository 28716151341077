
import bpm from '../assets/partners/bpm.png';
import delaware from '../assets/partners/delaware.png';
import famoco from '../assets/partners/famoco.png';
import gocloud_asia from '../assets/partners/gocloud_asia.png';
import hino from '../assets/partners/hino.png';
import hyland from '../assets/partners/hyland.jpg';
import isuzu from '../assets/partners/isuzu.png';
import microsoft from '../assets/partners/microsoft.png';
import ofs from '../assets/partners/ofs.png';
import paynet from '../assets/partners/paynet.png';
import pmjeep from '../assets/partners/pmjeep.jpg';
import sap from '../assets/partners/sap.png';
import sgi from '../assets/partners/sgi.png';
import ssc from '../assets/partners/ssc.png';
import team_ops from '../assets/partners/team_ops.png';
import three_i from '../assets/partners/three_i.jpg';
import cvent from '../assets/partners/Cvent_Logo.jpg';
import rang_i from '../assets/partners/Rang-I_Logo.png';
import bof from '../assets/partners/Bof.png';

import AllBank from '../assets/partners/All Bank.png';
import CVM from '../assets/partners/CVM Pawnshop.jpg';
import Maplecrest from '../assets/partners/Maplecrest Group.png';

const partner =[bpm,delaware,cvent,famoco,gocloud_asia,hino,hyland,isuzu,microsoft,ofs,
    paynet,pmjeep,sap,sgi,ssc,team_ops,three_i,cvent,rang_i,bof,AllBank,CVM,Maplecrest]
    
export default partner;