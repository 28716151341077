import React, { useState ,useEffect } from 'react';
import { NavLink } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { SERVER, EMAILFROM, EMAILTO } from "../keyvariable";
import axios from "axios";
import FadeInSection from "../components/FadeInSection";
import DOMPurify from 'dompurify';


 const Form = () => {

    useEffect(() => {
       
        const page = document.getElementById('contactform');
        page.scrollIntoView({behavior:'smooth',block:'start'});
      
    },[]);
   
        const [show, setShow] = useState(false);
        const [failedshow, setFailedshow] = useState(false);

        const [contact, setContact] =  useState({
            from: `EUODOO CUSTOMER INQUIRY ${EMAILFROM}`,
            to: "EMAILTO", 
            subject: "Euodoo Technologies Inc. Inquiry",
            html:""
        })

        const [form, setForm] = useState({
            name: "",
            company: "",
            email: "",
            mobile: "",
            country:"",
            message: ""
        })

        const onchangeForm = (e) => {
            const { name, value } = e.target;
        
            // Add additional validation logic
            if (name === "email" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                alert("Please enter a valid email address.");
                return;
            }
            if (name === "mobile" && value && !/^\+?[0-9\s\-]{7,15}$/.test(value)) {
                alert("Please enter a valid mobile number.");
                return;
            }
        
            setForm((prevForm) => ({ ...prevForm, [name]: value }));
        };

const SubmitInquiry = (e) => {
    e.preventDefault();

    const sanitizedHtml = DOMPurify.sanitize(`
        <div class="card container">
            <div class="card-body">
                <h5 class="card-title">New message from ${form.name}</h5>
                <p class="card-text"><b>Fullname:</b> ${form.name}</p>
                <p class="card-text"><b>Company:</b> ${form.company}</p>
                <p class="card-text"><b>Email Address:</b> ${form.email}</p>
                <p class="card-text"><b>Country:</b> ${form.country}</p>
                <p class="card-text"><b>Mobile Number:</b> ${form.mobile}</p>
                <p class="card-text"><b>Message:</b> ${form.message}</p>
            </div>
            <div class="card-footer">
                © 2023 | Euodoo Technologies Inc., 10/F One Global Place 25th Street cor. 5th Avenue Bonifacio Global City, 
                Taguig 1632 Philippines | www.euodoo.com.ph
            </div>
        </div>
    `);

    const contactForm = {
        from: contact.from,
        to: contact.to,
        subject: contact.subject,
        html: sanitizedHtml,
    };

    axios.post(`${SERVER}/send`, contactForm)
        .then((res) => {
            document.getElementById("form").reset();
            setShow(true);
        })
        .catch((error) => {
            setFailedshow(true);
            console.error("Error submitting inquiry:", error);
        });
};



  return (
    <div className='ContactForm' id="contactform">

        <div className="page-header fw-bold ">
        <FadeInSection direction="bottom">

            <h1 className='fw-bold text-center mx-auto'>Contact Us</h1>
        </FadeInSection>
        </div>
        <div className="container">
            <div className="contact-container d-flex flex-lg-row flex-column-reverse mt-lg-0 mt-5">
                <FadeInSection direction="left">
                    <div className="contact-info">
                        <div className="box">
                            <div className="icon"><img width="23" height="23" src="https://img.icons8.com/ios-filled/50/231557/marker.png" alt="marker"/></div>
                            <div className="text">
                                <h3>Address</h3>
                                <p>10/F One Global Place 25th Street cor. <br/>
                                5th Avenue Bonifacio Global City,<br/>
                                Taguig 1632 Philippines</p>
                            </div>
                        </div>
                        <div className="box">
                            <div className="icon"><img width="23" height="23" src="https://img.icons8.com/ios-filled/50/231557/phone.png" alt="phone"/></div>
                            <div className="text">
                                <h3>Telephone</h3>
                                <p>(02) 3224 4177 <br/>
                                +63 960 471 9671</p>
                            </div>
                        </div>
                        <div className="box">
                            <div className="icon"><img width="23" height="23" src="https://img.icons8.com/ios-glyphs/30/231557/new-post.png" alt="new-post"/></div>
                            <div className="text">
                                <h3>Email</h3>
                                <p>customercare@euodoo.com.ph</p>
                            </div>
                        </div>
                    </div>
                </FadeInSection>
                <FadeInSection direction="right">

                <div className='form'>
                    <Alert show={show} className=' fixed-top' variant="info">
                        <Alert.Heading>Message successfully submitted!</Alert.Heading>
                        <p>
                            Thank you for reaching us, we'll give you a feedback soon.
                        </p>
                        <div className="d-flex justify-content-end">
                        <Button onClick={() => setShow(false)} className='"btn-close" data-bs-dismiss="alert" aria-label="Close"' variant="outline-info text-dark">
                            <b>Dismiss</b>
                        </Button>
                        </div>
                    </Alert>
                    {/* -----------------------------------------end success alert-------------------------------------------------------- */}

                    {/* -----------------------------------------failed alert-------------------------------------------------------- */}
                    <Alert show={failedshow} className='fixed-top' variant="danger">
                        <Alert.Heading>Oops! Message unsuccessful</Alert.Heading>
                        <p>
                            Please try again or check your network. You may message us directly through a given email below.
                        </p>
                        <div className="d-flex justify-content-end">
                        <Button onClick={() => setFailedshow(false)} className='"btn-close" data-bs-dismiss="alert" aria-label="Close"' variant="outline-danger ">
                            <b>Dismiss</b>
                        </Button>
                        </div>
                    </Alert>
                    {/* -----------------------------------------end failed alert-------------------------------------------------------- */}

                    <form onSubmit={SubmitInquiry} className='contact-us-form d-flex flex-column gap-2' id="form">
                        <div className="form-title text-center">
                            <h3 className='fw-semibold mb-3'>Let's turn exciting possibilities into reality through technologies and innovations!</h3>
                            <NavLink to='/schedule' className="d-flex justify-content-center align-items-center gap-2"><ion-icon name="calendar-outline"></ion-icon> Schedule a Calendar Meeting</NavLink>
                            <p className='mt-3 fw-semibold'>Or send us a message</p>
                        </div>
                        <div>
                            <input onChange={onchangeForm} type="text" name="name" className="form-control" id="name" placeholder='Name' required/>
                        </div>
                        <div>
                            <input onChange={onchangeForm} type="text" name="company" className="form-control" id="company" placeholder='Company' required/>
                        </div>
                        <div>
                            <input onChange={onchangeForm} type="email" name="email" className="form-control" id="email" placeholder='Email' required/>
                        </div>
                        <div>
                            <input onChange={onchangeForm} type="tel" name="mobile" className="form-control" placeholder='Mobile Number' id="mobile"/>
                        </div>
                        <div>
                            <input onChange={onchangeForm} type="text" name="country" className="form-control" id="country" placeholder='Country' required/>
                        </div>
                        <div>
                            <textarea onChange={onchangeForm} className="form-control" name="message" id="message" placeholder='Message' required></textarea>
                        </div>
                        <button type="submit" className="formbutton btn mt-2 fw-bold w-100" value="Send">SEND MESSAGE</button>
                    </form>

                </div>
                </FadeInSection>

            </div> 
        </div> 
    </div>
  );
};

export default Form;

