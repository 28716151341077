import { NavLink } from "react-router-dom";

export default function Notfound(){
    return(
        <div className="container not-found-page d-flex justify-content-center align-items-center flex-column">
            <h1 className="fw-bold">404</h1>
            <p className="m-0 text-center">
                We couldn’t find the page you were looking for. Let’s get you back on track! <br /> 
                Click below to return to our homepage and explore our services. 
            </p>
            <NavLink className='btn home-cta fw-medium mt-3' to='/'>Home</NavLink>
        </div>
    
    )
}