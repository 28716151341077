import NewsBanner from "../assets/page-banners/news-banner.png"
import puvmpImg from "../assets/news/launching/pm_jeepney_launching1.png"
import Newsdb from "../data/newsdb";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

const NewsFilter = () => {

    useEffect(() => {
        const page = document.getElementById('news');
        page.scrollIntoView({behavior: 'smooth', block: 'start'});
    });

    function addEllipses(text) {
        const words = text.split(' ');
        
        if (words.length > 7) {
          return words.slice(0, 7).join(' ') + '...';
        }
      
        return text;
      }

    return (
        <div id="news" className="news">
            <div className="page-header news-header">
                <h1 className="fw-bold">Modern Jeepney</h1>
                <div className="sub-heading">
                    <p>The latest news for modern jeepneys</p>
                </div>
            </div>
            <div className="container">
                <div className="news-filter">
                    <ul className="list-unstyled m-0 p-3 flex-wrap text-center">
                        <li>
                            <a href="#" className="d-flex align-items-center justify-content-center">All</a>
                        </li>
                        <li>
                            <NavLink to="/news/filter" className="d-flex align-items-center justify-content-center">Modern Jeepney</NavLink>
                        </li>
                        <li>
                            <a href="#" className="d-flex align-items-center justify-content-center">Cybersecurity</a>
                        </li>
                        <li>
                            <a href="#" className="d-flex align-items-center justify-content-center">Social</a>
                        </li>
                        <li>
                            <a href="#" className="d-flex align-items-center justify-content-center">Career</a>
                        </li>
                    </ul>
                </div>

                <div className="category-section my-5">
                    <div className="section-title d-flex align-items-end mb-4">
                        <h2 className="m-0">Showing Results for: Modern Jeepney</h2>
                    </div>

                    <div className="row">
                        <div className="col-md-3 col-12">
                            <div className="card p-3">
                                <div className="card-image">
                                    <img src={puvmpImg} alt="asdf" className="rounded"/>
                                </div>
                                <div className="card-info mt-2">
                                    <span className="card-date fw-semibold">October 2, 2024</span>
                                    <h3 className="card-title m-0 fw-bold">PM Jeepyney Launching</h3>
                                    <p className="card-description m-0">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Error laboriosam quasi optio vero, quod iusto ullam molestias voluptatum accusantium aliquam corrupti inventore quibusdam </p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-3 col-12">
                            <div className="card p-3">
                                <div className="card-image">
                                    <img src={puvmpImg} alt="asdf" className="rounded"/>
                                </div>
                                <div className="card-info mt-2">
                                    <span className="card-date fw-semibold">October 2, 2024</span>
                                    <h3 className="card-title m-0 fw-bold">PM Jeepyney Launching</h3>
                                    <p className="card-description m-0">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Error laboriosam quasi optio vero, quod iusto ullam molestias voluptatum accusantium aliquam corrupti inventore quibusdam </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-12">
                            <div className="card p-3">
                                <div className="card-image">
                                    <img src={puvmpImg} alt="asdf" className="rounded"/>
                                </div>
                                <div className="card-info mt-2">
                                    <span className="card-date fw-semibold">October 2, 2024</span>
                                    <h3 className="card-title m-0 fw-bold">PM Jeepyney Launching</h3>
                                    <p className="card-description m-0">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Error laboriosam quasi optio vero, quod iusto ullam molestias voluptatum accusantium aliquam corrupti inventore quibusdam </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-12">
                            <div className="card p-3">
                                <div className="card-image">
                                    <img src={puvmpImg} alt="asdf" className="rounded"/>
                                </div>
                                <div className="card-info mt-2">
                                    <span className="card-date fw-semibold">October 2, 2024</span>
                                    <h3 className="card-title m-0 fw-bold">PM Jeepyney Launching</h3>
                                    <p className="card-description m-0">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Error laboriosam quasi optio vero, quod iusto ullam molestias voluptatum accusantium aliquam corrupti inventore quibusdam </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsFilter;
