import { useEffect } from "react";
import jobs from "../data/job.js";
const Career = ()=>{

    useEffect(() => {
        const page = document.getElementById('career');
        page.scrollIntoView({behavior:'smooth',block:'start'});
    });

    return(
        <>
            <div id="career" className="careers">
                <div className="page-header">
                    <div className="container d-flex justify-content-center align-items-center flex-column">
                        <h1 className='fw-bold'>Careers</h1>
                        {/* <div className="sub-heading">
                            <p className="m-0">
                                Explore our job openings and become a part of our passionate team. 
                                Click the APPLY button below to submit your application. We eagerly 
                                anticipate the chance to work with you!
                            </p>
                        </div> */}
                    </div>
                </div>

                <div className="career-body">
                    <div className="career-description text-center fw-semibold p-4">
                        <p className="mx-auto m-0">
                            Explore our job openings and become a part of our passionate team. 
                            Click the APPLY button below to submit your application. We eagerly 
                            anticipate the chance to work with you!
                        </p>
                    </div>
                    
                    <div className="no-jobs p-5">
                        <p className="text-center m-0">
                            We have no job openings available. Please revisit our site for future updates.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Career;