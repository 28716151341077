import Adminpage from '../../components/adminmenu';
import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { SERVER } from "../../keyvariable";
import axios from 'axios';
import moment from 'moment';

import { DatePicker } from 'rsuite';
import "rsuite/dist/rsuite-no-reset.min.css";




const CreateArticle = () =>{
    const { articleid } = useParams();

    const [title, setTitle] = useState("Add Title");
    const [publishDate, setPublishDate] = useState(moment().toDate());
    const [author, setAuthor] = useState("");

    const [files, setFiles] = useState([]);
    const coverRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const [subphoto, setSubPhoto] = useState([]);
    const fileInputRef = useRef(null);
    const [selectedSubFile, setSelectedSubFile] = useState(null);
    
    const sectionPhotoRef = useRef([]);
    const [selectedSectionFile, setSelectedSectionFile] = useState([]);

    const [category, setCategory] = useState("");
    const [subCategory, setSubCategory] = useState("");
    const [sections, setSections] = useState([{ columns: [] }]);
    const [preview, setPreview] = useState(false);
    const [data, setData] = useState([]);

   
    // const [sections, setSections] = useState([{ type: 'paragraph', content: '', columns:[] }]);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${SERVER}main/newsList`);
                setData(response.data.newsList);

                // console.log(response.data.newsList);
                

                if (articleid) {
                    const article = response.data.newsList.find(item => item._id === articleid);
                    if (article) {
                        setTitle(article.title);
                        setPublishDate(article.date ? new Date(article.date) : null);
                        setAuthor(article.author);
                        setCategory(article.category);
                        setSubCategory(article.subcategory);

                        //coverphoto
                        if (article.image && article.image.path) {
                            try {
                              const res = await fetch(`${SERVER}uploads/news/${article.image.originalName}`);
                              const blob = await res.blob();
                              const file = new File([blob], article.image.originalName, { type: article.image.contentType });
                              
                              // Add file to the array
                              setFiles((prevFiles) => [...prevFiles, file]);
                
                              // Set the file input field
                              const dataTransfer = new DataTransfer();
                              dataTransfer.items.add(file);

                              setSelectedFile(file.name);
                              if (coverRef.current) {
                                coverRef.current.files = dataTransfer.files;
                              }
                            } catch (error) {
                              console.error("Error fetching the image:", error);
                            }
                        }

                        //subphoto
                        if (article.subphoto && Array.isArray(article.subphoto)) {
                            try {
                                const photoFiles = await Promise.all(
                                    article.subphoto.map(async (photos) => {
                                        const res = await fetch(`${SERVER}uploads/news/${photos.originalName}`);
                                        const blob = await res.blob();
                                        return new File([blob], photos.originalName, { type: photos.contentType });
                                    })
                                );
                        
                                // console.log(photoFiles);
                                
                                setSubPhoto(photoFiles);
                        
                                const dataTransfer = new DataTransfer();
                                photoFiles.forEach((file) => dataTransfer.items.add(file));
                                // if (fileInputRef.current) {
                                //     fileInputRef.current.files = dataTransfer.files;
                                // }

                                if (fileInputRef.current) {
                                    fileInputRef.current.files = dataTransfer.files;
                                    setSelectedSubFile(
                                        dataTransfer.files.length === 1 ? 
                                            dataTransfer.files[0].name
                                        : dataTransfer.files.length > 1 ?
                                            `${dataTransfer.files.length} files`
                                        :''
                                    );
                                }
                            } catch (error) {
                                console.error("Error fetching subphotos:", error);
                            }
                        }

                        //sections
                        if (article.sections && Array.isArray(article.sections)) {
                            try {
                                // Process sections
                                const updatedSections = await Promise.all(
                                    article.sections.map(async (section, sectionIndex) => {
                                        if (section.columns && Array.isArray(section.columns)) {
                                            const updatedColumns = await Promise.all(
                                                section.columns.map(async (column, columnIndex) => {
                                                    if (column.type === 'image' && column.image && column.image.originalName) {
                                                        try {
                                                            // Fetch the image from the server
                                                            const res = await fetch(`${SERVER}uploads/news/${column.image.originalName}`);
                                                            const blob = await res.blob();
                                                            const file = new File([blob], column.image.originalName, { type: blob.type });
                                
                                                            // Update the file input field
                                                            const dataTransfer = new DataTransfer();
                                                            dataTransfer.items.add(file);
                                
                                                            if (
                                                                sectionPhotoRef.current[sectionIndex] &&
                                                                sectionPhotoRef.current[sectionIndex][columnIndex]
                                                            ) {
                                                                sectionPhotoRef.current[sectionIndex][columnIndex].files = dataTransfer.files;
                                                            }
                                
                                                            // Update the column with the new file details
                                                            column.image.file = file;
                                                            column.image.path = `${SERVER}uploads/news/${column.image.originalName}`;
                                                            column.image.originalName = column.image.originalName;
                                
                                                            // Update selectedSectionFile state
                                                            setSelectedSectionFile((prevSelectedFiles) => {
                                                                const updatedFiles = [...prevSelectedFiles];
                                                                if (!updatedFiles[sectionIndex]) {
                                                                    updatedFiles[sectionIndex] = [];
                                                                }
                                                                updatedFiles[sectionIndex][columnIndex] = file.name;
                                                                return updatedFiles;
                                                            });
                                                        } catch (error) {
                                                            console.error(`Error fetching image for column ${column._id}:`, error);
                                                        }
                                                    }
                                                    return column;
                                                })
                                            );
                                            section.columns = updatedColumns;
                                        }
                                        return section;
                                    })
                                );
                                
                                // Update the sections state
                                setSections(updatedSections);
                                

                            } catch (error) {
                                console.error("Error fetching sections:", error);
                            }
                        }
                        
                        
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [articleid]);

    const handleSubmit = async (e, status) => {
        e.preventDefault();
    
        const formData = new FormData();
        formData.append('title', title);
        formData.append('date', publishDate);
        formData.append('category', category);
        formData.append('status', status);
        formData.append('subcategory', subCategory);
        formData.append('image', files[0]);  
        formData.append('author', author);
    
        // Append multiple subphotos
        subphoto.forEach((file) => {
            formData.append('subphoto', file); 
        });

        const sectionsArray = [];

        sections.forEach((section, sectionIndex) => {
            const sectionData = { columns: [] };

            section.columns.forEach((column, columnIndex) => {
                const columnData = { type: column.type };

                // Add content for 'paragraph' type
                if (column.type === 'paragraph' && column.content) {
                    columnData.content = column.content;
                }

                // Add image data for 'image' type
                if (column.type === 'image' && column.image && column.image.file) {
                    columnData.image = {
                        path: column.image.file.path, 
                        contentType: column.image.file.mimetype,
                        originalName: column.image.originalName || `image_${sectionIndex}_${columnIndex}.jpg`
                    };

                    // Append the image file to FormData
                    formData.append(
                        `sectionsContent`,
                        column.image.file,
                        column.image.originalName || `image_${sectionIndex}_${columnIndex}.jpg`
                    );
                }

                // Add column data to the section
                sectionData.columns.push(columnData);
            });

            // Add the section data to the sections array
            sectionsArray.push(sectionData);
        });

        // Append the sections array as a JSON string to FormData
        formData.append('sections', JSON.stringify(sectionsArray));
            
        // for (let [key, value] of formData.entries()) {
        //     console.log(key, value);
        // }

        
        

        try {
            if(articleid){
                const response = await axios.patch(`${SERVER}main/edit_news/${articleid}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                // Handle response
                if (response.status === 200) {
                    // console.log('Article created successfully:', response.data);
                    resetForm(); 
                } else {
                    console.error('Error submitting article.');
                }
                
            }else{
                const response = await axios.post(`${SERVER}main/create_news`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                // Handle response
                if (response.status === 200) {
                    // console.log('Article created successfully:', response.data);
                    resetForm(); 
                } else {
                    console.error('Error submitting article.');
                }
            }
            

            
        } catch (error) {
            console.error('Error creating article:', error.response?.data || error.message);
        }
    };


    const validateForm = () =>{
        try {
            let formErrors = {};

            if (!category.trim()) formErrors.category = "Category field is required";
            if (!title.trim()) formErrors.title = "Title field is required";
            if (!publishDate.trim()) formErrors.publishDate = "Date field is required";
            

            
        } catch (error) {
            console.log(error);
            
        }
    }
    
    

    const handleDateSched = (newDate) => {
        setPublishDate(newDate || null); 
    };


    //cover photo

    const handleCoverPhoto = (e) => {
        // const files = Array.from(e.target.files);   
        // setFiles((prevFiles) => [...prevFiles, ...files]);

        const files = e.target.files;
        if (files.length > 0) {
            setFiles([files[0]]);
            setSelectedFile(files[0].name); // Set the selected file
        } else {
            setFiles([]);
            setSelectedFile('');
        }
    }

    const removeCover = (index)=>{
        const updatedPhotos = files.filter((_, i) => i !== index);
        setFiles(updatedPhotos);

        const dataTransfer = new DataTransfer();
        updatedPhotos.forEach(file => dataTransfer.items.add(file));

        if (coverRef.current) {
            coverRef.current.files = dataTransfer.files;
        } 
        
    }


    //subphoto

    const handleSubPhoto = (e) => {
        const newFiles = Array.from(e.target.files);
    
        // Filter out any new files that already exist in subphoto
        const uniqueFiles = newFiles.filter(newFile => 
            !subphoto.some(existingFile => existingFile.name === newFile.name)
        );
    
        const updatedPhotos = [...subphoto, ...uniqueFiles];
        setSubPhoto(updatedPhotos);

        const dataTransfer = new DataTransfer();
        updatedPhotos.forEach(file => dataTransfer.items.add(file));
    
        fileInputRef.current.files = dataTransfer.files;

        if(dataTransfer.files.length === 1){
            setSelectedSubFile(dataTransfer.files[0].name);
        }
        else if(dataTransfer.files.length > 1){
            setSelectedSubFile(`${dataTransfer.files.length} files`);
        }
        else{
            setSelectedSubFile('');
        }
        
    };

    const removePhoto = (index) => {
        const updatedPhotos = subphoto.filter((_, i) => i !== index);
        setSubPhoto(updatedPhotos);

        const dataTransfer = new DataTransfer();
        updatedPhotos.forEach(file => dataTransfer.items.add(file));

        if (fileInputRef.current) {
            fileInputRef.current.files = dataTransfer.files;
            setSelectedSubFile(
                dataTransfer.files.length === 1 ? 
                    dataTransfer.files[0].name
                : dataTransfer.files.length > 1 ? 
                    `${dataTransfer.files.length} files`
                : ''
            );
        }
    };

    // category
    const handleCategory = (value) =>{
        setCategory(value);
        
    }

    const handleSubCategory =(value)=>{
        setSubCategory(value);
    }


    //section
    
    const addColumn = (sectionIndex) => {
        setSections((prevSections) => {
            const updatedSections = [...prevSections];
            updatedSections[sectionIndex].columns.push({
                type: "paragraph",
                content: "", 
            });
            return updatedSections;
        });
    };
    


    const removeColumn = (sectionIndex, columnIndex) => {
        setSections((prevSections) => {
            const updatedSections = [...prevSections];
            updatedSections[sectionIndex].columns = updatedSections[sectionIndex].columns.filter((_, i) => i !== columnIndex);
            return updatedSections;
        });
    
        setSelectedSectionFile((prevSelectedFiles) => {
            const updatedFiles = [...prevSelectedFiles];
            if (updatedFiles[sectionIndex]) {
                updatedFiles[sectionIndex].splice(columnIndex, 1);
            }
            return updatedFiles;
        });
    };


    const handleColumnTypeChange = (sectionIndex, columnIndex, type) => {
        setSections((prevSections) => {
            const updatedSections = [...prevSections];
            updatedSections[sectionIndex].columns[columnIndex].type = type;

            if (type === 'paragraph') {
                updatedSections[sectionIndex].columns[columnIndex].content = '';
                updatedSections[sectionIndex].columns[columnIndex].image = null;
            } else if (type === 'image') {
                updatedSections[sectionIndex].columns[columnIndex].content = '';
                updatedSections[sectionIndex].columns[columnIndex].image = { path: '', contentType: '', originalName: '' };
            }

            return updatedSections;
        });
    };


    const handleColumnContentChange = (sectionIndex, columnIndex, value) => {
        setSections((prevSections) => {
            const updatedSections = [...prevSections];
            updatedSections[sectionIndex].columns[columnIndex].content = value;
            return updatedSections;
        });
    };


    const handleImageChange = (sectionIndex, columnIndex, files) => {
        if (!files || files.length === 0) return;
    
        const file = files[0];
        const filePath = URL.createObjectURL(file);
    
        // Update sections state
        setSections((prevSections) => {
            const updatedSections = [...prevSections];
            updatedSections[sectionIndex].columns[columnIndex].image = {
                file,
                path: filePath,
                contentType: file.type,
                originalName: file.name,
            };
            return updatedSections;
        });
    
        // Update selectedSectionFile state
        setSelectedSectionFile((prevSelectedFiles) => {
            const updatedFiles = prevSelectedFiles.map((section) => [...(section || [])]); // Deep copy each section array
            if (!updatedFiles[sectionIndex]) {
                updatedFiles[sectionIndex] = [];
            }
            updatedFiles[sectionIndex][columnIndex] = file.name; // Update specific file name
            return updatedFiles;
        });
    };
    

    const addSection = () => {
        setSections([...sections, { columns: [] }]);
    };


    const removeSection = (sectionIndex) => {
        setSections((prevSections) => prevSections.filter((_, i) => i !== sectionIndex));
    };


    const resetForm = () =>{
        
        setTitle("Add Title");
        setFiles([]);
        setSubPhoto([]);
        setPublishDate(moment().toDate());
        setCategory("");
        // setInputStatus("");
        setSubCategory("");
        setSections([{ columns: [] }]);
        setPreview(false);
        setAuthor("");


        if (fileInputRef.current) {
          fileInputRef.current.value = ""; 
          setSelectedSubFile("");
        }
        
        if (coverRef.current) {
          coverRef.current.value = ""; 
          setSelectedFile("");
        }

        if (sectionPhotoRef.current) {
            sectionPhotoRef.current.value = ""; 
            setSelectedSectionFile("");
        }
    }

    const handlePreview = () =>{
        setPreview((prev)=>(!prev));
        
    }




    return(
        <div style={{minHeight:"100vh"}}>
           <Adminpage/>
          
            <div className="secondnav pt-5 mt-md-0 pt-md-4 flex-column-reverse flex-md-row">
                <div className="me-md-auto mt-2 mt-md-0">
                    <input type="text" value={title} className="title-create"  name="title" onChange={(e)=>setTitle(e.target.value)}/>
                </div>
                <div className='d-flex pt-5 pt-md-0' style={{gap:'10px'}}>
                    <button  className='btn btn-secondary'
                        onClick={(e)=>{
                        e.preventDefault();
                        // setInputStatus('draft');
                        handleSubmit(e, 'draft');
                        }}>
                        Save Draft
                    </button>
                    <button className='btn btn-secondary'
                        type='button'
                        onClick={(e)=>{
                            e.preventDefault();
                            handlePreview();
                        }}
                    >
                        {
                            !preview ? 
                                <p className='m-0'>Preview</p>
                            :
                                <p className='m-0'>Edit Draft</p>
                        }
                        
                    </button>
                    <button className='btn btn-primary' 
                        onClick={(e)=>{
                        e.preventDefault();
                        // setInputStatus('publish');
                        handleSubmit(e, 'publish');
                        }}>
                        Publish
                    </button>
                </div>
            </div> 

            <form>

            <div className="side-content">
                    {
                        !preview ? (
                            <div className="create-article">

                            <div className="mb-3 article-component">
                                <label htmlFor="cover-photo" className="form-label">Cover Photo:</label>


                                {/* <input type="file" ref={coverRef} className="form-control" accept=".png, .jpg, .jpeg"  id="cover-photo" name="img" onChange={handleCoverPhoto}/> */}
                                <div>
                                    <input
                                        type="file"
                                        ref={coverRef}
                                        className="form-control"
                                        accept=".png, .jpg, .jpeg"
                                        id="cover-photo"
                                        name="img"
                                        onChange={handleCoverPhoto}
                                        style={{ display: 'none' }}
                                    />
                                    
                                    <div className="recreate-file">
                                        <div className="me-auto recreate-chosen"
                                            onClick={() => coverRef.current && coverRef.current.click()}
                                        >
                                            Choose File
                                        </div>
                                        <label htmlFor="cover-photo" className="custom-file-label">
                                            {selectedFile || 'No file chosen'}
                                        </label>
                                    </div>
                                    
                                </div>


                                <div className="image-preview" style={{ marginTop: '20px' }}>
                                    {files.length > 0 && files.map((file, index) => (
                                        <div key={index} style={{ display: 'inline-block', margin: '10px', position: 'relative' }}>
                                            <img
                                                src={URL.createObjectURL(file)}
                                                alt={`Preview ${index}`}
                                                style={{ width: '100px', height: '100px', objectFit: 'cover', border: '1px solid #ccc' }}
                                            />
                                            <button
                                                onClick={() => removeCover(index)}
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 0,
                                                    background: 'red',
                                                    color: 'white',
                                                    border: 'none',
                                                    borderRadius: '50%',
                                                    cursor: 'pointer',
                                                    width: '20px',
                                                    height: '20px'
                                                }}
                                            >
                                                <i className="fa-solid fa-xmark"></i>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            
                            </div>


                                <div className="mb-3 article-component">
                                    <label htmlFor="cover-photo" className="form-label">Category:</label>
                                    <select
                                        className="form-select mb-2"
                                        aria-label="Default select example"
                                        id='category'
                                        name='category'
                                        onChange={e=>handleCategory(e.target.value)}
                                        value={category}
                                    >
                                        <option value=''>Select a category</option>
                                        <option value='featured'>Featured</option>
                                        <option value='modern_jeepney'>Modern Jeepney</option>
                                        <option value='cyber_security'>Cyber Security</option>
                                        <option value='services'>Services</option>
                                    </select>
                                </div>

                                <div className="mb-3 article-component">
                                    <label htmlFor="cover-photo" className="form-label">Sub Category:</label>
                                    <select
                                        className="form-select mb-2"
                                        aria-label="Default select example"
                                        id='subCategory'
                                        name='subCategory'
                                        onChange={e=>handleSubCategory(e.target.value)}
                                        value={subCategory}
                                    >
                                        <option value='none'>None</option>
                                        <option value='modern_jeepney'>Modern Jeepney</option>
                                        <option value='cyber_security'>Cyber Security</option>
                                        <option value='services'>Services</option>
                                    </select>
                                </div>

                            <div className="mb-3 dateSched article-component">
                                <label htmlFor="dateSched" className="form-label">Publishing Date:</label>
                                <DatePicker 
                                format="MM/dd/yyyy hh:mm aa"
                                value={publishDate} 
                                onChange={handleDateSched}
                                id="dateSched"
                                className="form-control"
                                />
                                
                            </div>

                            <div className="mb-3 article-component">
                                <label className="form-label" htmlFor="author">Author Name:</label>
                                <input id="author" name="author" className="form-control" value={author} onChange={(e)=>setAuthor(e.target.value)}/>
                            </div>

                            <div className='d-flex flex-column article-component mb-3'>
                                <label htmlFor="sections" className="form-label">Sections:</label>

                                {sections.map((section, sectionIndex) => (
                                    <div key={sectionIndex} className="mb-3 d-flex flex-column" style={{border:'2px dotted black', padding:'1%', borderRadius:'15px'}}>
                                        <p style={{fontSize:'18px'}}>Section {sectionIndex + 1}:</p>

                                        {section.columns.map((column, columnIndex) => (
                                            <div key={columnIndex} className="mb-2">
                                                <p  className='mb-2' style={{fontSize:'16px'}} htmlFor={`column_type_${sectionIndex}_${columnIndex}`}>Column Type</p>
                                                <select
                                                    className="form-select mb-2"
                                                    value={column.type}
                                                    onChange={(e) => handleColumnTypeChange(sectionIndex, columnIndex, e.target.value)}
                                                    id={`column_type_${sectionIndex}_${columnIndex}`}
                                                >
                                                    <option value="paragraph">Paragraph</option>
                                                    <option value="image">Image</option>
                                                </select>

                                                {column.type === 'paragraph' ? (
                                                    <textarea
                                                        value={column.content || ""}
                                                        onChange={(e) => handleColumnContentChange(sectionIndex, columnIndex, e.target.value)}
                                                        className="form-control mb-2"
                                                        placeholder="Enter paragraph text"
                                                    />
                                                ) : (
                                                    
                                                    <div>
                                                        {/* File input for image */}
                                                        <input
                                                            ref={(el) => {
                                                                if (!sectionPhotoRef.current[sectionIndex]) {
                                                                    sectionPhotoRef.current[sectionIndex] = [];
                                                                }
                                                                sectionPhotoRef.current[sectionIndex][columnIndex] = el;
                                                            }}
                                                            type="file"
                                                            accept=".png, .jpg, .jpeg"
                                                            className="form-control"
                                                            onChange={(e) => handleImageChange(sectionIndex, columnIndex, e.target.files)}
                                                            id={`section-photo-${sectionIndex}-${columnIndex}`}
                                                            style={{display:'none'}}
                                                        />

                                                        <div className="recreate-file">
                                                            <div className="me-auto recreate-chosen"
                                                                onClick={() => sectionPhotoRef.current[sectionIndex]?.[columnIndex]?.click()}
                                                            >
                                                                Choose File
                                                            </div>
                                                            <label htmlFor={`section-photo-${sectionIndex}-${columnIndex}`} className="custom-file-label">
                                                                {selectedSectionFile[sectionIndex]?.[columnIndex] || 'No file chosen'}
                                                            </label>
                                                        </div>

                                                        
                                                        {/* Display image preview if the image is set */}
                                                        {column.image?.path && (
                                                            <img
                                                                src={column.image.path}  // Use the path (or URL) of the image
                                                                alt={column.image.originalName}
                                                                className="img-thumbnail mt-2"
                                                                style={{ maxWidth: "100px", maxHeight: "100px" }}
                                                            />
                                                        )}
                                                    </div>

                                                    
                                                )}

                                                <button type="button" onClick={() => removeColumn(sectionIndex, columnIndex)} className="btn btn-secondary mt-2 mb-3">
                                                <i className="fa-solid fa-trash"></i>
                                                </button>
                                            </div>
                                        ))}

                                        <button type="button" onClick={() => addColumn(sectionIndex)} className="btn btn-primary mb-2">Add Column</button>
                                        <button type="button" onClick={() => removeSection(sectionIndex)} className="btn btn-danger mt-2">
                                            Remove Section
                                        </button>
                                    </div>
                                ))}
                                <button type="button" onClick={addSection} className="btn btn-primary mb-3">
                                    Add Section
                                </button>
                            </div>


                            <div className="mb-3 article-component">
                                <label htmlFor="sub-photo" className="form-label">Additional photos:</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    accept=".png, .jpg, .jpeg"
                                    id="sub-photo"
                                    name="img"
                                    multiple
                                    onChange={handleSubPhoto}
                                    ref={fileInputRef}  // Reference to the input
                                    style={{ display: 'none' }}
                                />

                                <div className="recreate-file">
                                    <div className="me-auto recreate-chosen"
                                        onClick={() => fileInputRef.current && fileInputRef.current.click()}
                                    >
                                        Choose File
                                    </div>
                                    <label htmlFor="sub-photo" className="custom-file-label">
                                        {selectedSubFile || 'No file chosen'}
                                    </label>
                                </div>

                                {/* Show selected image previews below */}
                                <div className="image-preview" style={{ marginTop: '20px' }}>
                                    {subphoto.length > 0 && subphoto.map((file, index) => (
                                        <div key={index} style={{ display: 'inline-block', margin: '10px', position: 'relative' }}>
                                            <img
                                                src={URL.createObjectURL(file)}
                                                alt={`Preview ${index}`}
                                                style={{ width: '100px', height: '100px', objectFit: 'cover', border: '1px solid #ccc' }}
                                            />
                                            <button
                                                type="button" 
                                                onClick={() => removePhoto(index)}
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 0,
                                                    background: 'red',
                                                    color: 'white',
                                                    border: 'none',
                                                    borderRadius: '50%',
                                                    cursor: 'pointer',
                                                    width: '20px',
                                                    height: '20px'
                                                }}
                                            >
                                                <i className="fa-solid fa-xmark"></i>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                                
                            </div>

                        

                        </div>
                        
                        )

                        :
                        (
                        <div >
                            <div className="page-header article-header" style={{ backgroundImage: files.length > 0 ? `url(${URL.createObjectURL(files[0])})` : 'none' }}>
                                <div className="article-overlay"></div>
                                <h1 className="fw-bold text-center">{title}</h1>
                                <div className="sub-heading">
                                    <p>Published: {moment(publishDate).format('MMM/DD/YYYY h:mm A')}</p>
                                    <p>By: {author}</p>
                                </div>
                            </div>

                            <div className="container">
                                <div className="article-body p-100">
                                    {sections.map((sectionItem, index) => (
                                        <div key={index}>
                                            <div className="right-image-article mb-3 d-flex align-items-center">
                                            {sectionItem.columns.map((column, columnIndex) => {
                                                    return (
                                                        column.type === 'paragraph' ? (
                                                            <div 
                                                                className="article-text-wrapper d-flex flex-column me-md-3 mb-3 flex-grow-1" 
                                                                key={columnIndex}
                                                                style={{ display: 'flex', alignItems: 'stretch' }}
                                                            >
                                                                <p 
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: column.content.replace(/\n/g, '<br />')
                                                                    }}
                                                                    style={{ margin: 0 }}
                                                                ></p>
                                                            </div>
                                                        ) : (
                                                            <div 
                                                                className="article-image-wrapper mx-auto mb-3 flex-grow-1" 
                                                                key={columnIndex}
                                                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch' }}
                                                            >
                                                                <img
                                                                    src={column.image.path}
                                                                    alt={column.image.alt || `Image ${columnIndex + 1}`}
                                                                    style={{ maxWidth: '100%', height: 'auto', objectFit: 'contain' }}
                                                                />
                                                            </div>
                                                        )
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div id="carouselExampleDark" className="carousel carousel-dark slide p-100">
                            {/* Carousel Indicators */}
                            {subphoto.length > 1 && (
                            <div className="carousel-indicators p-100">
                                {/* Determine the number of buttons based on the length of subphoto */}
                                {Array.from({ length: Math.ceil(subphoto.length / 2) }).map((_, index) => (
                                    <button
                                        type="button"
                                        data-bs-target="#carouselExampleDark"
                                        data-bs-slide-to={index}
                                        className={index === 0 ? "active" : ""}
                                        aria-current={index === 0 ? "true" : "false"}
                                        aria-label={`Slide ${index + 1}`}
                                        key={index}
                                    ></button>
                                ))}
                            </div>
                        )}


                        {/* Carousel Inner */}
                        <div className="carousel-inner">
                        {subphoto.length > 0 && subphoto.reduce((acc, item, index) => {
                            // Check if the next item exists and pair the images, or push the single item if it's the last one
                            if (index % 2 === 0) {
                                const pair = subphoto[index + 1] ? [item, subphoto[index + 1]] : [item];
                                acc.push(pair);
                            }
                            return acc;
                        }, []).map((pair, index) => (
                            <div
                                className={`carousel-item ${index === 0 ? "active" : ""}`}
                                key={index} // Unique key for each slide
                                data-bs-interval="10000"
                            >
                                <div className="row justify-content-center">
                                    {pair.map((photo, photoIndex) => (
                                        <div className="col-md-6" key={photoIndex}>
                                            {photo && photo instanceof Blob ? (  // Check if photo is a Blob or File
                                                <img
                                                    src={URL.createObjectURL(photo)}
                                                    alt={`Image ${photoIndex + 1}`}
                                                    className="d-block w-100"
                                                    style={{
                                                        maxWidth: "100%",
                                                        height: "300px", // Adjust height
                                                        objectFit: "contain",
                                                    }}
                                                />
                                            ) : (
                                                <div>Invalid image data</div>  // Fallback UI if photo is invalid
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                        </div>



                        {/* Carousel Controls */}
                        <div className="p-4">
                            {subphoto.length > 1 && ( // Show controls only if there are more than 1 image
                                <>
                                    <button
                                        className="carousel-control-prev"
                                        type="button"
                                        data-bs-target="#carouselExampleDark"
                                        data-bs-slide="prev"
                                    >
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button
                                        className="carousel-control-next"
                                        type="button"
                                        data-bs-target="#carouselExampleDark"
                                        data-bs-slide="next"
                                    >
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </>
                            )}
                        </div>
                    </div>



                            

                        </div>
                        )
                        
                    }
                
            </div>
            </form>
        </div>
    )
}

export default CreateArticle;