import Navigation from '../components/navigation'; // Custom navigation for Home page
import Footer from '../components/footer';

function HomeLayout({ children }) {
  return (
    <>
      <Navigation theme="light" />
      {children}
      <Footer />
    </>
  );
}

export default HomeLayout;