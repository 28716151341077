import { useState } from 'react'
import { NavLink } from 'react-router-dom'

import heroImg from "../../assets/eventmanagement/cvent-banner.png"
import checklistImg from "../../assets/eventmanagement/45.png"
import ctaInfoImg from "../../assets/eventmanagement/banner2.png"
import euodooLogo from "../../assets/logo/euodoo-dark.png"

import checkmark from "../../assets/services-page/ems/checkmark-done-circle.svg"

import PageBanner from "../../assets/services-page/ems/EMS Page Banner.png"

import contentManagement from "../../assets/services-page/ems/content-management.png"
import diagramming from "../../assets/services-page/ems/diagramming-and-seating.png"
import eventPayment from "../../assets/services-page/ems/event-payment-processing.png"
import eventPlanning from "../../assets/services-page/ems/event-planning-solutions.png"
import eventRegistration from "../../assets/services-page/ems/event-registration.png"
import leadCapturing from "../../assets/services-page/ems/lead-capturing.png"
import onlineSolutions from "../../assets/services-page/ems/onsite-solutions.png"
import venueSearching from "../../assets/services-page/ems/venue-searching.png"

const EMS = () => {

    const [activeCircle, setActiveCircle] = useState('')

    const handleActiveCircle = (name) => {
        setActiveCircle(prevActiveCircle => prevActiveCircle === name ? null : name)
    };

    // console.log(activeCircle)

    return (
        <>
            <div className="ems">
                <div className="page-header">
                    <div className="container d-flex justify-content-center align-items-center flex-column">
                        <img src={PageBanner} className="page-banner" alt="EMS Page Banner" />
                        <h1 className='fw-bold text-center'>Event Management System</h1>
                        {/* <div className="sub-heading">
                            <p className="m-0">
                                Euodoó Technologies’ Event Management System features a versatile platform 
                                designed to enhance virtual, in-person, and hybrid events.
                            </p>
                        </div> */}
                    </div>
                </div>

                <div className="ems-content-container">

                    <div className="ems-hero container my-5">
                        <div className="ems-hero-wrapper d-flex justify-content-center align-items-center gap-5 flex-md-row-reverse flex-column-reverse text-center text-md-start">
                            <div className="ems-banner-img d-flex justify-content-center align-items-center">
                                <img className="img-fluid" src={heroImg} alt='' />
                            </div>
                            <div className="ems-banner-text d-flex justify-content-center align-items-start flex-column">
                                <h2 className="fw-bold m-0">Game-changing Event Platform</h2>
                                <p>From seamless event management to powerful audience engagement tools, we've got you covered.</p>
                                {/* <NavLink to='/contactus' className='btn home-cta mx-auto mx-md-0'>Inquire Now</NavLink> */}

                                {/* <div className="ems-box p-5 rounded">
                                    asdf
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="ems-info d-flex justify-content-center align-items-center text-center">
                        <div className="container d-flex justify-content-center align-items-center flex-column py-5">
                            <h3 className="text-capitalize fw-semibold mw-800">Create a memorable attendee experiences across diverse event formats</h3>
                            <p className='mw-800'>
                                Euodoó Technologies, Inc. ensures that attendees have a seamless experience at your event. Whether your 
                                hybrid event is conducted in real-time, pre-recorded, or falls somewhere in between, we guarantees 
                                continuous engagement, facilitating event content discovery and consumption.
                            </p>
                        </div>
                    </div>

                    <div className="ems-list-info py-5">
                        <div className="container d-flex justify-content-between align-items-center h-100 flex-column flex-md-row">
                            <div className="checklist">
                                <h3 className="fw-semibold">With our all-in-one event platform you can:</h3>
                                <ul className='list-unstyled'>
                                    <li className='d-flex justify-content-start align-items-center gap-2'><img src={checkmark} className='user-select-none' alt='checkmark'/> <p className='m-0'>Say goodbye to scattered plans and endless spreadsheets</p></li>
                                    <li className='d-flex justify-content-start align-items-center gap-2'><img src={checkmark} className='user-select-none' alt='checkmark'/> <p className='m-0'>Streamline your event planning, organization, and execution</p></li>
                                    <li className='d-flex justify-content-start align-items-center gap-2'><img src={checkmark} className='user-select-none' alt='checkmark'/> <p className='m-0'>Whether virtual, hybrid, or onsite, we've got you covered</p></li>
                                </ul>
                            </div>
                            <div className="checklist-img overflow-hidden">
                                <img src={checklistImg} className="img-fluid user-select-none" alt='ems in mobile and laptop view'/>
                            </div>
                        </div>
                    </div>

                    <div className="demo-cta text-center bg-dark text-light px-4">
                        <div className="container">
                                <h4 className="fw-bold mb-3">Request a free demo!</h4>
                                <NavLink to='/contactus' className="btn home-cta fw-semibold">Schedule Now</NavLink>
                        </div>
                    </div>

                    <div className="ems-graphic-info">
                        <div className="ems-graphic-info-wrapper container d-flex justify-content-between align-items-center flex-column py-5">
                            <div className="graphic-header text-center">
                                <h3 className="fw-semibold">Ignite your event’s transformation with a single solution!</h3>
                                <p>
                                    An essential component of our service is delivering a comprehensive solution to our clients, 
                                    ensuring that they have a memorable, quantifiable, and valuable experience when hosting an event. 
                                    At Euodoó Technologies, Inc., we've got all your needs covered, offering dependable and secure 
                                    solutions for planning, promoting, and enhancing engagement for your virtual, hybrid, and 
                                    in-person events.
                                </p>
                            </div>
                            <div className="graphic-container mt-3">
                                {/* <div className="ems-euodoo-logo">
                                    <img src={euodooLogo} alt="" />
                                </div> */}
                                <div className="top-circles d-flex justify-content-center align-items-center my-2">
                                    <div className={`graphic-circle ${activeCircle === 'venueSearching' ? 'active' : ''}`}>
                                        <div className='graphic-logo' onClick={() => {handleActiveCircle('venueSearching')}}><img src={venueSearching} alt="location" /></div>
                                        <p className='m-0'>Venue Sourcing</p>
                                        <div className={`graphic-circle-info left text-start px-3 text-light ${activeCircle === 'venueSearching' ? 'active' : ''}`}>
                                            <span className='m-0 d-flex justify-content-center align-items-center h-100'>Find the ideal venue for your event.</span>
                                        </div>
                                    </div>
                                    <div className={`graphic-circle ${activeCircle === 'onlineSolutions' ? 'active' : ''}`}>
                                        <div className='graphic-logo' onClick={() => {handleActiveCircle('onlineSolutions')}}><img src={onlineSolutions} alt="idea bulb" /></div>
                                        <p className='m-0'>Online Solutions</p>
                                        <div className={`graphic-circle-info right text-start px-3 text-light ${activeCircle === 'onlineSolutions' ? 'active' : ''}`}>
                                            <span className='m-0 d-flex justify-content-center align-items-center h-100 right'>Simplify event planning online.</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="upper-middle-circles d-flex justify-content-center align-items-center my-2">
                                    <div className={`graphic-circle ${activeCircle === 'eventPlanning' ? 'active' : ''}`}>
                                        <div className='graphic-logo' onClick={() => {handleActiveCircle('eventPlanning')}}><img src={eventPlanning} alt="calendar" /></div>
                                        <p className='m-0'>Event Planning Solutions</p>
                                        <div className={`graphic-circle-info left text-start px-3 text-light ${activeCircle === 'eventPlanning' ? 'active' : ''}`}>
                                            <span className='m-0 d-flex justify-content-center align-items-center h-100'>Plan and execute events effortlessly.</span>
                                        </div>
                                    </div>
                                    <div className={`graphic-circle ${activeCircle === 'diagramming' ? 'active' : ''}`}>
                                        <div className='graphic-logo' onClick={() => {handleActiveCircle('diagramming')}}><img src={diagramming} alt="seatings" /></div>
                                        <p className='m-0'>Diagramming & Seating</p>
                                        <div className={`graphic-circle-info right text-start px-3 text-light ${activeCircle === 'diagramming' ? 'active' : ''}`}>
                                            <span className='m-0 d-flex justify-content-center align-items-center h-100'>Design layouts and seating plans.</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="lower-middle-circles d-flex justify-content-center align-items-center my-2">
                                    <div className={`graphic-circle ${activeCircle === 'eventRegistration' ? 'active' : ''}`}>
                                        <div className='graphic-logo' onClick={() => {handleActiveCircle('eventRegistration')}}><img src={eventRegistration} alt="registration form" /></div>
                                        <p className='m-0'>Event Registration</p>
                                        <div className={`graphic-circle-info left text-start px-3 text-light ${activeCircle === 'eventRegistration' ? 'active' : ''}`}>
                                            <span className='m-0 d-flex justify-content-center align-items-center h-100'>Streamline attendee sign-ups.</span>
                                        </div>
                                    </div>
                                    <div className={`graphic-circle ${activeCircle === 'contentManagement' ? 'active' : ''}`}>
                                        <div className='graphic-logo' onClick={() => {handleActiveCircle('contentManagement')}}><img src={contentManagement} alt="content management" /></div>
                                        <p className='m-0'>Content Management</p>
                                        <div className={`graphic-circle-info right text-start px-3 text-light ${activeCircle === 'contentManagement' ? 'active' : ''}`}>
                                            <span className='m-0 d-flex justify-content-center align-items-center h-100'>Manage and share event content.</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom-circles d-flex justify-content-center align-items-center my-2">
                                    <div className={`graphic-circle ${activeCircle === 'eventPayment' ? 'active' : ''}`}>
                                        <div className='graphic-logo' onClick={() => {handleActiveCircle('eventPayment')}}><img src={eventPayment} alt="" /></div>
                                        <p className='m-0'>Event Payment Processing</p>
                                        <div className={`graphic-circle-info left text-start px-3 text-light ${activeCircle === 'eventPayment' ? 'active' : 'payment'}`}>
                                            <span className='m-0 d-flex justify-content-center align-items-center h-100'>Process payments securely.</span>
                                        </div>
                                    </div>
                                    <div className={`graphic-circle ${activeCircle === 'leadCapturing' ? 'active' : ''}`}>
                                        <div className='graphic-logo' onClick={() => {handleActiveCircle('leadCapturing')}}><img src={leadCapturing} alt="magnet" /></div>
                                        <p className='m-0'>Lead Capturing</p>
                                        <div className={`graphic-circle-info right text-start px-3 text-light ${activeCircle === 'leadCapturing' ? 'active' : ''}`}>
                                            <span className='m-0 d-flex justify-content-center align-items-center h-100'>Collect and track attendee leads.</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="graphic-accordion">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="ems-cta-info container d-flex justify-content-center align-items-center flex-column mt-5">
                        <h3 className="fw-semibold text-center">Entrust your project with us</h3>
                        <p className='text-center'>
                            Enhance the effectiveness of all your gatherings and functions by 
                            utilizing user-friendly, integrated event technology to maximize their impact.
                        </p>

                        <div className="d-flex justify-content-center align-items-center py-5 gap-5 flex-column flex-md-row">
                            <img src={ctaInfoImg} className='user-select-none' alt='responsive view of ems' />
                            <div className="cta-info">
                                <h4 className='text-center text-md-start'>You can contact us by either scheduling an appointment or reaching out to us.</h4>
                                <div className="cta-btns d-flex justify-content-center align-items-center justify-content-md-start">
                                    {/* <NavLink to='/' className='btn home-cta me-1'>Inquiry</NavLink> */}
                                    <NavLink to='/contactus' className='btn home-cta'>Request a Demo</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default EMS;